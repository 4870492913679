import { Component, TemplateRef, Renderer2, ElementRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { ButtonAction } from 'src/app/common/button-action';
import { FilterForm } from 'src/app/common/filter-form';
import { FilterTable } from 'src/app/common/filter-table';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { tableCol } from 'src/app/common/table-cols';
import { accionConst } from 'src/app/constants/general-constats';
import { ProductResponse } from 'src/app/response/productos/producto.response';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { IngresoAlmacen_Response } from 'src/app/response/ingreso_almacen/ingreso_almacen.response';
import { MovimientoAlmacenComponent } from '../movimiento-almacen/movimiento-almacen.component';
import { Movimiento_Almacen_Service } from 'src/app/services/movimiento_almacen.service';
@Component({
	selector: 'app-reporte-movimiento-almacen',
	standalone: true,
	imports: [FontAwesomeModule, TableModule, TablePaggingComponent, MovimientoAlmacenComponent, CommonModule, ButtonModule, DialogModule, FormsModule, PaginatorModule, InputTextModule],
	templateUrl: './reporte-movimiento-almacen.component.html',
	styleUrl: './reporte-movimiento-almacen.component.css',
	providers: [
		BsModalService,
		SharedDataService
	],
})
export class ReporteMovimientoAlmacenComponent {
	faTableList = faTableList;
	config = bsConfigModal;
	modalref: BsModalRef = new BsModalRef();
	totalRecords: number = 0;
	loading: boolean = false;
	titleModal: string = "";

	buttonActions: ButtonAction[] = [
		{ accion: accionConst.add, data: {}, active: true },
		{ accion: accionConst.edit, data: {}, active: false },
		{ accion: accionConst.delete, data: {}, active: false },
		{ accion: accionConst.config, data: {}, active: false }
	];

	eventsSubject: Subject<void> = new Subject<void>();
	valuesTable: IngresoAlmacen_Response[] = [];
	cols: tableCol[] = [
		{ field: "id_movimiento", header: "ID" },
		{ field: "nombre_almacen_origen", header: "Almacén Origen" },
		{ field: "nombre_almacen_destino", header: "Almacén Destino" },
		{ field: "productos", header: "Productos" },
		{ field: "fecha", header: "Fecha" },
		{ field: "estado", header: "Estado" },
		{ field: "detalles", header: "Detalles" },

	];

	IngresosAlmacen_Response: IngresoAlmacen_Response[] = []
	filterForm: FilterForm[] = [];
	IngresoAlmacen_Selected: IngresoAlmacen_Response = new IngresoAlmacen_Response();

	constructor(

		private _Movimiento_Almacen_Service: Movimiento_Almacen_Service,
		private modalService: BsModalService,

	) {
	}

	ngOnInit(): void {
		this.GetIngresosAlmacen_Api();
	}

	GetIngresosAlmacen_Api() {
		this.loading = false;
		this._Movimiento_Almacen_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				this.loading = true;
				if (response_data) {
					response_data.forEach((ingreso: any) => {
						// Modificar el estado
						if (ingreso.estado === 2) {
							ingreso.estado = 'Activo';
							ingreso.style = { color: 'green', fontWeight: 'bold' }; // Establecer estilos directamente
						}
						// if (ingreso.almacen_id_origen === 1 || ingreso.almacen_id_destino === 2) {
						// 	ingreso.almacen_id_origen = 'ESPERANZA';
						// 	ingreso.almacen_id_destino = 'CENTRO';
						// } 


					});

					this.IngresosAlmacen_Response = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				this.loading = true;
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	adjustProductData(products: ProductResponse[]): ProductResponse[] {

		return products.map(product => {
			product.estado = product.estado === 2 ? "Activo" : "Inactivo";
			return product;
		});
	}

	findProductosArray(data: any): any[] | null {

		// Si la respuesta no es un array, busca en las propiedades
		for (const arr in data) {
			console.log("entrado a este for");
			if (Array.isArray(data[arr])) {
				// Si encuentra un array dentro de una propiedad, devuélvelo
				return data[arr];
			}
		}
		// Si no encuentra un array de productos, devuelve null
		return null;
	}
	changeFilter(name: string, array: FilterTable[]) {
		this.filterForm.map(x => {
			if (x.name == name) {
				x.data = array;
			}
		});
	}
	recibeAction(data: ButtonAction, template: TemplateRef<any>, templateconfigUser: TemplateRef<any>) {
		this.IngresoAlmacen_Selected = data.data;
		switch (data.accion) {
			case accionConst.add:
				this.IngresoAlmacen_Selected = new IngresoAlmacen_Response();
				this.addElement(template)
				break;
			case accionConst.edit:
				this.editElement(template)
				break;
			case accionConst.config:
				this.configElement(templateconfigUser);
				break;
			case accionConst.delete:
				break;

		}

	}
	addElement(template: TemplateRef<any>) {
		this.titleModal = "Registro Movimiento Almacén";
		this.openModalWithClass(template, "modal-xl modal-custom");
	}

	editElement(template: TemplateRef<any>) {
		this.titleModal = "Editar Producto";
		this.openModalWithClass(template, "gray modal-md");
	}
	configElement(template: TemplateRef<any>) {

	}

	deleteElement(data: ProductResponse) {

	}
	openModalWithClass(template: TemplateRef<any>, customclass: string) {
		this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config),);
	}

	closeModal(res: boolean) {
		this.modalref.hide();
		if (res) {

		}
	}
}

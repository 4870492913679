export class Ventas_Response {
    id_venta: number;
    empleado_id: number;
    cliente_id: number;
    fecha: number;
    monto: number;
    descuento: number;
    descuento_porcentaje: number;
    igv: number;
    monto_total: number;
    detalles: string | null;
    id_comprobante: number;
    tipo_comprobante: number;
    serie_comprobante: number;
    numero_comprobante: number;
    tipo_documento: number;
    numero_documento: string;
    nombre_completo_cliente: string;

    constructor() {
        this.id_venta = 0;
        this.empleado_id = 0;
        this.cliente_id = 0;
        this.fecha = 0;
        this.monto = 0;
        this.descuento = 0;
        this.descuento_porcentaje = 0;
        this.igv = 0;
        this.monto_total = 0;
        this.detalles = '';
        this.id_comprobante = 0;
        this.tipo_comprobante = 0;
        this.serie_comprobante = 0;
        this.numero_comprobante = 0;
        this.tipo_documento = 0;
        this.numero_documento = '';
        this.nombre_completo_cliente = '';
    }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoutes, ElementRoutes, SettingRoutes } from './admin.routes';
import { AdminPageNotFoundComponent } from './views/admin-page-not-found/admin-page-not-found.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { EventsComponent } from './views/events/events.component';
import { TestComponent } from './views/events/test/test.component';
import { ProfileComponent } from './views/settings/profile/profile.component';
import { UsersComponent } from './views/settings/users/users.component';
import { ProductoListComponent } from '../pages/producto/producto-list/producto-list.component';
import { ProcesoVentaRegisterComponent } from '../pages/proceso-venta/proceso-venta-register/proceso-venta-register.component';
import { IngresoAlmacenReporteComponent } from '../pages/almacenes/ingreso-almacen-reporte/ingreso-almacen-reporte.component';
import { AlmacenProductoReporteComponent } from '../pages/almacenes/almacen-producto-reporte/almacen-producto-reporte.component';
import { EditVentaComponent } from '../pages/proceso-venta/editar-venta/edit-venta.component';
import { LoginGuard } from '../guards/login.guard';
import { ReporteMovimientoAlmacenComponent } from '../pages/almacenes/reporte-movimiento-almacen/reporte-movimiento-almacen.component';
import { ReporteVentasComponent } from '../pages/proceso-venta/reporte-ventas/reporte-ventas.component';
import { ReporteStockVistaComponent } from '../pages/reporte-stock-productos/reporte-stock-vista/reporte-stock-vista.component';








const routes: Routes = [
  {
    path: '',
    redirectTo: AdminRoutes.Dashboard,
    pathMatch: 'full',
  },
  ////////////
  {
    title: 'Dashboard',
    path: AdminRoutes.Dashboard,
    component: DashboardComponent,
  },


  /////////
  {
    title: 'Events',
    path: AdminRoutes.Events,
    component: EventsComponent,
    children: [
      {
        path: 'testing',
        component: TestComponent,
        outlet: 'test',
      },
    ],
  },

  {
    title: 'Almacen',
    path: AdminRoutes.Almacenes,
    children: [
      {
        title: 'Reporte de Almacen',
        path: ElementRoutes.Almacen_Ingreso,
        canActivate:[LoginGuard],
        component: IngresoAlmacenReporteComponent,
        //canActivate:[LoginGuard],
      },
      {
        title: 'Reporte Stock',
        path: ElementRoutes.Almacen_Stock,
        canActivate:[LoginGuard],
        component: AlmacenProductoReporteComponent,
      },
      {
        title: 'Ingreso Almacen',
        path: ElementRoutes.Ingreso_Almacen,
        canActivate:[LoginGuard],
        component: ReporteMovimientoAlmacenComponent,
      },
    
    ],
  },
  ///esto vale  
  {
    title: 'Catalogo',
    path: AdminRoutes.Catalogo,
    children: [
      // {
      //   title: 'Categorias',
      //   path: ElementRoutes.Categorias,
      //   component: CategoriaListComponent,
      // },
     
      {
        title: 'Productos',
        path: ElementRoutes.Productos,
        component: ProductoListComponent,
        canActivate:[LoginGuard],
      },
    
    ],
  },

  {
    title: 'Procesos',
    path: AdminRoutes.Procesos,
    children: [
      {
        title: 'Registro Venta',
        path: ElementRoutes.Reporte_venta,
        canActivate:[LoginGuard],
        component: ProcesoVentaRegisterComponent,
      },
      {
        title: 'Venta',
        path: ElementRoutes.Proceso_venta,
        canActivate:[LoginGuard],
        component: ReporteVentasComponent,
      },
      {
        title: 'Editar-Venta',
        path: ElementRoutes.Editar_venta,
        canActivate:[LoginGuard],
        component: EditVentaComponent,
      },
      {
        title: 'stock-minimo',
        path: ElementRoutes.ReporteStock,
        canActivate:[LoginGuard],
        component: ReporteStockVistaComponent,
      },
    
    ],
  },
 //////////

  {
    path: AdminRoutes.Settings,
    children: [
      {
        title: 'Settings',
        path: SettingRoutes.Profile,
        component: ProfileComponent,
      },
      {
        title: 'Users',
        path: SettingRoutes.Users,
        component: UsersComponent,
      },
    ],
  },
  { path: '**', component: AdminPageNotFoundComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}

import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { RegistroIngresoAlmacenComponent } from '../registro-ingreso-almacen/registro-ingreso-almacen.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { ButtonAction } from 'src/app/common/button-action';
import { accionConst } from 'src/app/constants/general-constats';
import { Subject } from 'rxjs';
import { AlmacenProductoReporte_Response } from 'src/app/response/almacen-producto/almacen_producto_reporte.request';
import { tableCol } from 'src/app/common/table-cols';
import { FilterForm } from 'src/app/common/filter-form';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { FilterTable } from 'src/app/common/filter-table';
import { RegistroAlmacenProductoComponent } from '../registro-almacen-producto/registro-almacen-producto.component';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';
import { TooltipModule } from 'primeng/tooltip';
import { Almacen_Service } from 'src/app/services/almacen.service';

interface Almacen {
  id: string;
  name: string;
}

@Component({
  selector: 'app-almacen-producto-reporte',
  standalone: true,
  imports: [FontAwesomeModule, TableModule, TablePaggingComponent, TooltipModule, RegistroIngresoAlmacenComponent, CommonModule, ButtonModule, DialogModule, FormsModule, PaginatorModule, InputTextModule, RegistroAlmacenProductoComponent],
  templateUrl: './almacen-producto-reporte.component.html',
  styleUrl: './almacen-producto-reporte.component.css',
  providers: [
    BsModalService,
    SharedDataService
  ],
})
export class AlmacenProductoReporteComponent {

  faTableList = faTableList;
  config = bsConfigModal;
  modalref: BsModalRef = new BsModalRef();
  totalRecords: number = 0;
  loading: boolean = false;
  titleModal: string = "";
  almacenes: any[] = [];
  selectedAlmacen: Almacen | undefined;
  almacenes_reportes: any[] = [];
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  disableButton: boolean = false;
  AlmacenProductoDB: any = {};
  almacen_reporte: any = {};
  buttonActions: ButtonAction[] = [
    { accion: accionConst.add, data: {}, active: true },
    { accion: accionConst.edit, data: {}, active: false },
    { accion: accionConst.delete, data: {}, active: false },
    { accion: accionConst.config, data: {}, active: false }
  ];

  eventsSubject: Subject<void> = new Subject<void>();
  valuesTable: AlmacenProductoReporte_Response[] = [];
  cols: tableCol[] = [
    { field: "id_almacen_producto", header: "ID" },
    { field: "fecha", header: "Fecha" },
    { field: "nombre_almacen", header: "Almacén" },
    { field: "nombre_largo", header: "Producto" },
    { field: "unidad_medida", header: "U Medidad" },
    { field: "cantidad", header: "Cant/Stock" },
    { field: "precio", header: "Precio" },
    { field: "caracteristicas", header: "Características" },
    { field: "detalles", header: "Detalles" }
  ];

  AlmacenProducto_Response: AlmacenProductoReporte_Response[] = []
  filterForm: FilterForm[] = [];
  AlmacenProducto_Selected: AlmacenProductoReporte_Response = new AlmacenProductoReporte_Response;
  //  productos: ProductResponse [] = [];
  //  tCategoria: CategoriaResponse [] = [];
  almacen: any = {}
  constructor(
    private _AlmacenProducto_Service: AlmacenProducto_Service,
    private modalService: BsModalService,
    private _Almacen_Service: Almacen_Service,
    
    
  ) {
  }

  ngOnInit(): void {
    this.GetAll_AlmacenProducto_Api();
    this.getAlmacenes_Api();
  }

  closeModal(res: boolean) {
    this.modalref.hide();
    if (res) {
      this.GetAll_AlmacenProducto_Api();
    }
  }


	getAlmacenes_Api(): void {
		const request_data = {
		  usuario: localStorage.getItem('id_usuario')
		};
		this._Almacen_Service.getByOther(request_data).subscribe({
		  next: (response: any) => {
			const response_data = response.data;
			console.log(response_data);
			if (response_data) {
			  this.almacenes_reportes = response_data;
	
			  // Accediendo al campo id_almacen
			  const idAlmacen = response_data.id_almacen;
			  console.log('ID del almacén:', idAlmacen);
	
			  setTimeout(() => {
				this.eventsSubject.next();
			  }, 200);
			} else {
			  console.error('No se encontró un array de productos en la respuesta.');
			}
		  },
		  error: (error) => {
			console.log("Error al llamar el servicio", error);
		  }
		});
	  }




  GetAll_AlmacenProducto_Api() {
    this.loading = false;
    const request_data = {
      almacen: localStorage.getItem('almacenes')
  };
    this._AlmacenProducto_Service.getByOther(request_data,"https://api-sisgeven.cloud.elastika.pe/api/v1/reporte-almacen-productos/").subscribe({
      next: (response: any) => {
        const response_data = response.data;
        this.loading = true;
        if (response_data) {
          this.AlmacenProducto_Response = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        this.loading = true;
        console.log("Error al llamar el servicio", error);
      }
    });
  }


  changeFilter(name: string, array: FilterTable[]) {
    this.filterForm.map(x => {
      if (x.name == name) {
        x.data = array;
      }
    });
  }
  recibeAction(data: ButtonAction, template: TemplateRef<any>, templateconfigUser: TemplateRef<any>) {
    this.AlmacenProducto_Selected = data.data;
    switch (data.accion) {
      case accionConst.add:
        this.AlmacenProducto_Selected = new AlmacenProductoReporte_Response;
        this.addElement(template)
        break;
      case accionConst.edit:
        this.editElement(template)
        break;
      case accionConst.config:
        this.configElement(templateconfigUser);
        break;
      case accionConst.delete:
        // this.deleteElement(this.IngresoAlmacen_Selected);
        break;

    }

  }

  imprimirReporte(template: any) {
    if (this.almacen_reporte.id_almacen) { // Verificar si se ha seleccionado un almacén


      const idVenta = this.almacen_reporte.id_almacen;
     
      const url = `https://api-sisgeven.cloud.elastika.pe/api/v1/reporte-stock-almacen-pdf/almacen/${idVenta}`;
      window.open(url, '_blank');
    } else {
      alert_warning("Selecione un Almacén");
      //console.log("No se ha seleccionado un almacén.");
    }
  }

  addElement(template: TemplateRef<any>) {
    this.titleModal = "Registro Almacen Producto";
    this.openModalWithClass(template, "modal-xl modal-custom");
  }

  editElement(template: TemplateRef<any>) {
    this.titleModal = "Editar Producto";
    this.openModalWithClass(template, "gray modal-md");
  }
  configElement(template: TemplateRef<any>) {

  }

  deleteElement(data: AlmacenProductoReporte_Response) {
    // this._productoService.delete(data.id_producto).subscribe(
    //   (data: number) => {
    //     if (data > 0) {

    //       this.getProducto();
    //     }
    //   },
    //   err => { }
    // );

  }
  openModalWithClass(template: TemplateRef<any>, customclass: string) {
    this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config),);
  }



}

export class IngresoAlmacen_Request {
    ingreso_almacen_id? : number | null;
    almacen_id? : number;
    proveedor_id? : number;
    codigo? : string | null;
    detalles? : string | null;
    productos? : JSON;

    constructor () {
        this.ingreso_almacen_id = 0;
        this.almacen_id = 0;
        this.proveedor_id = 0;
        this.codigo = '';
        this.detalles = '';
        this.productos = JSON;
    }
}
export class Cliente_Response{
    id_cliente : number;
    codigo: number;
    tipo_documento: number;
    numero_documento: string;
    apellido_paterno: string | null;
    apellido_materno: string | null;
    nombres: string;
    distrito_id_direccion: string | null;
    direccion: string;
    celulares: string | null;
    correos:  string | null;
    estado: number;
    detalles: string | null;

    constructor(){
        this.id_cliente = 0;
        this.codigo = 0;
        this.tipo_documento =  0;
        this.numero_documento =  "";
        this.apellido_paterno = "" ;
        this.apellido_materno =  "";
        this.nombres = "";
        this.distrito_id_direccion = "";
        this.direccion = "";
        this.celulares = "";
        this.correos = "";
        this.estado  =0;
        this.detalles = "";  
    }
}

export class MovimientoAlmacen_Response{
    almacen_id_origen: number;
    almacen_id_destino: number;
    productos: string;
   
    
    constructor(){
        this.almacen_id_origen = 0;
        this.almacen_id_destino = 0 ;
        this.productos = "" ;
        
    
}
}
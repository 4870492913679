import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrudService } from './util/crud.service';
import { Proveedor_Response } from '../response/proveedor/proveedor.response';
import { behaidorInterface } from './interfaces/behaidor-interface';
import { urlConstants } from '../constants/url-constants';
import { Proveedor_Request } from '../request/proveedor/proveedor.request';
    
@Injectable({
    providedIn: 'root'
})
export class ProveedorService extends CrudService<Proveedor_Request, Proveedor_Response>
implements behaidorInterface<Proveedor_Response[]> { 

    private dataSource = new BehaviorSubject<Proveedor_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.proveedor);
	}

    setValueBehaidor(value: Proveedor_Response[]): void {
        this.dataSource.next(value);
    }
    getValueBehaidorSubject() {
        return this.dataSource.getValue();
    }
    clearBehaidorSubject(): void {
        this.dataSource.next([]);
    }
};
import { Injectable } from '@angular/core';
import { CrudService } from './util/crud.service';

import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { urlConstants } from '../constants/url-constants';

import { behaidorInterface } from './interfaces/behaidor-interface';
import { ProductRequest } from '../request/producto/producto.request';
import { ProductResponse } from '../response/productos/producto.response';

@Injectable({
	providedIn: 'root',
})
export class ProductoService
	extends CrudService<ProductRequest, ProductResponse>
	implements behaidorInterface<ProductResponse[]>
{
	private dataSource = new BehaviorSubject<ProductResponse[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.producto);
	}

	setValueBehaidor(value: ProductResponse[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}

import { CategoriaResponse } from "../categorias/categoria.respose";

export class ProductResponse {
    id_producto: number;
    categoria_producto_id: number;
    codigo: string;
    nombre: string;
    nombre_corto: string;
    nombre_largo: string;
    nombre_largo_categoria: string;
    estado: any;

    categoria_producto : CategoriaResponse;

    constructor(){
        this.id_producto = 0;
        this.categoria_producto_id = 0 ;
        this.codigo = "" ;
        this.nombre = "";
        this.nombre_largo_categoria = "";
        this.nombre_corto = "";
        this.nombre_largo = "";
        this.estado = 0;  
        this.categoria_producto = new CategoriaResponse()
}
}
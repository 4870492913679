/*botones  */
export class ButtonAction {
    accion: number;
    active: boolean;
    data: any;
    

    constructor() {
        this.accion = 0;
        this.active = false;
        this.data = {};
    }
}

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ButtonAction } from 'src/app/common/button-action';
import { tableCol } from 'src/app/common/table-cols';
import { accionConst, paggingConst } from 'src/app/constants/general-constats';
import { fadeInRight400ms } from 'src/assets/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/assets/animations/scale-in.animation';
import { stagger40ms } from 'src/assets/animations/stagger.animation';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-pagging',
  standalone: true,
  imports: [TableModule,CommonModule,RouterModule,ButtonModule,TooltipModule],
  templateUrl: './table-pagging.component.html',
  styleUrls: ['./table-pagging.component.css'],
  animations: [
    stagger40ms,
    scaleIn400ms,
    fadeInRight400ms
  ]

})
export class TablePaggingComponent implements OnInit, OnDestroy, AfterViewInit{
  @Input() cols: tableCol[] = [];
  @Input() data: any[] = [];
  @Input() totalRecords: number = 0;
  @Input() loading: boolean = true;
  @Input() events: Observable<void> = new Observable<void>();

  @Input() button_actions: ButtonAction[] = [
    { accion: accionConst.add, data: {}, active: true },
    { accion: accionConst.edit, data: {}, active: true },
    { accion: accionConst.edit_enlace, data: {}, active: true },
    { accion: accionConst.delete, data: {}, active: true },
    { accion: accionConst.config, data: {}, active: true }
  ];

  @Output() changePage_Emit = new EventEmitter();
  @Output() Button_Emit = new EventEmitter();
 
  @Output() editarClicked: EventEmitter<any> = new EventEmitter();
  // rowsPaginatable: number = paggingConst.FILAS_PAGINADO;
  lazyLoading: boolean = false;

  buttonAdd: ButtonAction = new ButtonAction();
  buttonEdit: ButtonAction = new ButtonAction();
  buttonEdit_link: ButtonAction = new ButtonAction();
  buttonConfig: ButtonAction = new ButtonAction();
  buttonDelete: ButtonAction = new ButtonAction();


  private eventsSubscription: Subscription = new Subscription();

  constructor(private router: Router) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.lazyLoading = true;
    }, 100);
  }

  ngOnInit(): void {

    this.eventsSubscription = this.events.subscribe(() => {
      this.loading = false;
    });
    this.assigmentButton();
  }
  navigateToRegistroIngreso() {
		this.router.navigate(['/admin/proceso/Registro_Venta']);
	}
  assigmentButton() {
    this.buttonAdd = this.button_actions.filter(x => x.accion == accionConst.add)[0];
    this.buttonEdit = this.button_actions.filter(x => x.accion == accionConst.edit)[0];
    this.buttonConfig = this.button_actions.filter(x => x.accion == accionConst.config)[0];
    this.buttonDelete = this.button_actions.filter(x => x.accion == accionConst.delete)[0];
  }
  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  emitEditarClicked(data: any): void {
    this.editarClicked.emit(data);
  }

  refreshData(d: any) {
    let nroPagina = d.page + 1;
    this.loading = true;
    setTimeout(() => {
      this.changePage_Emit.emit(nroPagina);
      this.loading = false;
    }, 100);
  }

  addElement() {
    console.log('Haciendo clic en addElement');
    let obj: any = { accion: accionConst.add, data: {} };
    this.Button_Emit.emit(obj);
  }

  editElement(el: any) {
    console.log('Haciendo clic en editar');
    let obj: any = { accion: accionConst.edit, data: el };
    this.Button_Emit.emit(obj);
  }
  
  editElement_link(el: any) {
    console.log('Haciendo clic en editar');
    let obj: any = { accion: accionConst.edit, data: el };
    this.Button_Emit.emit(obj);
  }

  configElement(rowData: any) {
     console.log('Datos del elemento:', rowData);
    let obj: any = { accion: accionConst.config, data: rowData };
    this.Button_Emit.emit(obj);

  }

  deleteElement(el: any) {


    Swal.fire({
      title: "¿Está seguro?",
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Eliminado!',
        //   'Registro eliminado de forma satisfactoría.',
        //   'success'
        // )
        let obj: any = { accion: accionConst.delete, data: el };
        this.Button_Emit.emit(obj);
      }

    })
  }

}

<p-card class="carta">
	<div class="row">
		<div class="col-lg-2">
			<h3>Editar venta</h3>
		</div>
		<div class="col-lg-2">
			<span class="p-float-label">
				<p-dropdown inputId="almacen--id" class="drop-size" [options]="almacenes" optionValue="id_almacen" optionLabel="nombre" [(ngModel)]="almacen.id_almacen" [showClear]="true" placeholder="Seleciona Almacén" [readonly]="true">
				</p-dropdown>
				<label for="almacen--id">Seleccione almacén</label>
			</span>
		</div>
		<div class="col-lg-6 d-flex align-items-center justify-content-start">
			<label class=" p-2">Descuento Descuento Planilla</label>
			<p-inputSwitch [(ngModel)]="isChecked" (onChange)="Check_Function($event)"></p-inputSwitch>

		</div>
		<div class="col-lg-8"></div>
	</div>

	<div class="row">
		<div class="col-lg-2">
			<div class="form-group">
				<p-dropdown class="drop-size-1" [options]="clientes" optionValue="id_cliente" (onChange)="getClienteById_Api($event)" optionLabel="nombre_commpleto" [(ngModel)]="ventaDb.id_cliente" [showClear]="true" [filter]="true" (onFilter)="filterClientes($event)" placeholder="Selecciona Cliente">
				</p-dropdown>
			</div>
		</div>
		<div class="col-lg-1">
			<div class="form-group">
			
				<p-dropdown class="drop-size-1" [options]="tipos_documento" optionValue="id_tipo_documento"
					optionLabel="nombre_tipo_documento" [(ngModel)]="ventaDb.tipo_documento" placeholder="Selecione"
					[readonly]="true">
				</p-dropdown>
			</div>
		</div>
		<div class="col-lg-2">
			<div class="form-group"  >
				<label for="numeroDocumento">Numero Documento:</label>
				<input class="input-size" type="text" id="numeroDocumento" pInputText
				  [(ngModel)]="ventaDb.numero_documento" readonly>
			  </div>
		</div>
		<div class="col-lg-3">
			<div class="form-group">
				<label for="apellidosNombres">Apellido y Nombres:</label>
				<input class="input-size" type="text" id="apellidosNombres" pInputText
					[(ngModel)]="ventaDb.nombre_completo_cliente" readonly>
			</div>
		</div>
		<div class="col-lg-2">
			<div class="form-group">
				<label for="">Dirección </label>
				<input type="text" pInputText [(ngModel)]="cliente.direccion">
			</div>
		</div>
		<div class="col-lg-2">
			<div class="form-group">
				<label for="fecha">Fecha</label>
				<p-calendar id="fecha" [(ngModel)]="ventaDb.fecha_venta" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
			</div>
		</div>
	</div>
	<div class="row">

	</div>
</p-card>

<div class="row">
	<div class="botton-1">
		<p-button icon="pi pi-plus" (click)="agregarFila()" label="Agregar Productos"></p-button>
	</div>
	<div class="col-md-8">
		<p-card>
			<div class="row">
				<table>
					<thead>
						<tr>
							<th width="10%">Cantidad</th>
							<th width="40%">Producto</th>
							<th width="20%">Talla</th>
							<th width="10%">Precio</th>
							<th width="10%">Descuento</th>
							<th width="10%">Sub Total </th>
						</tr>
					</thead>
					<tbody>
						<!-- ---------------------------------------- tr para listar productos de la API-DB ---------------------------------------- -->
						<tr *ngFor="let producto of productos; let i = index">
							<td>
								<p-inputNumber [(ngModel)]="producto.cantidad" mode="decimal" [useGrouping]="false" (keyup)="calularSubtotalProducto(i)" class="input-size"> </p-inputNumber>
							</td>
							<td>
								<p-dropdown [options]="productos_dinamico__db" optionValue="id_producto"
									optionLabel="nombre_largo" [showClear]="true" placeholder="Producto"
									[(ngModel)]="producto.id_producto"
									(onChange)="getAlmacenProductosByProductoIdAlmacenId_Api($event, i)"
									[filter]="true"class="input-size" class="input-size"></p-dropdown>
							</td>
							<td>
								<p-dropdown [options]="almacen_productos_dinamico__db[i]"
									optionValue="id_almacen_producto" optionLabel="nombre_corto" [showClear]="true"
									placeholder="Producto" [(ngModel)]="producto.id_almacen_producto"
									(onChange)="getAlmacenProductoById_Api($event, i)" [filter]="true" class="input-size"></p-dropdown>
							</td>
							<td class="td-size"><input class="input-size" type="text" pInputText
									[(ngModel)]="producto.precio" disabled /></td>
							<td class="td-size"><input class="input-size" type="text" pInputText
									[(ngModel)]="producto.descuento" (keyup)="calularSubtotalProducto(i)" [readOnly]="true"></td>
							<td class="td-size"><input class="input-size" type="text" pInputText
									[(ngModel)]="producto.precio_venta" disabled></td>
							<td>
								<p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash"
									(click)="eliminarFila(i)" severity="danger"></p-button>
							</td>
						</tr>
						<!-- -------------------------------------------------------------------------------------------------------------------------- -->
						
						<!-- ------------------------------------------- tr para agregar productos - NUEVOS ------------------------------------------- -->
						<tr *ngFor="let producto_new of productos_new; let i = index">
							<td>
								<p-inputNumber [(ngModel)]="producto_new.cantidad" mode="decimal" [useGrouping]="false" (keyup)="calularSubtotalProducto_New(i)" class="input-size"> </p-inputNumber>
							</td>
							<td>
								<p-dropdown [options]="productos_dinamico__db" optionValue="id_producto"
									optionLabel="nombre_largo" [showClear]="true" placeholder="Producto"
									[(ngModel)]="producto_new.id_producto"
									(onChange)="getAlmacenProductosByProductoIdAlmacenId_New_Api($event, i)"
									[filter]="true"class="input-size" class="input-size"></p-dropdown>
							</td>
							<td>
								<p-dropdown [options]="almacen_productos_dinamico__new__db[i]"
									optionValue="id_almacen_producto" optionLabel="nombre_corto"  [showClear]="true"
									placeholder="Producto" [(ngModel)]="producto_new.id_almacen_producto"
									(onChange)="getAlmacenProductoById_New_Api($event, i)" [filter]="true" class="input-size"></p-dropdown>
							</td>
							<td class="td-size"><input class="input-size" type="text" pInputText
									[(ngModel)]="producto_new.precio" disabled /></td>
							<td class="td-size"><input class="input-size" type="text" pInputText
									[(ngModel)]="producto_new.descuento"  (keyup)="calularSubtotalProducto_New(i)"></td>
							<td class="td-size"><input class="input-size" type="text" pInputText
									[(ngModel)]="producto_new.precio_venta" disabled></td>
							<td>
								<p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash"
									(click)="eliminarFila_New(i)" severity="danger"></p-button>
							</td>
						</tr>
						
						<!---------------------------------------------------------------------------------------------------------------------->

						<tr>
							<td colspan="5" class="text-end fw-bold">Importe Total</td>
							<td> <input class="input-size" type="text" pInputText [(ngModel)]="ventaDb.monto_total" disabled> </td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="row">
				<div class="col-lg-3">
					<div class="d-flex justify-content-end align-items-center">
						<label for="precio_venta" class="pr-1">Subtotal</label>
						<p-inputNumber inputId="precio_venta" [disabled]="true" prefix="S/. "
							[(ngModel)]="total_venta__sin_descuento"></p-inputNumber>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="d-flex justify-content-end align-items-center">
						<label for="descuento" class="pr-1">Descuento</label>
						<p-inputNumber inputId="descuento" [ngModel]="ventaDb.descuento" [disabled]="true" prefix="S/. "></p-inputNumber>
					  </div>
					  
				</div>
				<div class="col-lg-2">
					<div class="d-flex justify-content-end align-items-center">
						<label for="igv" class="pr-1">IGV</label>
						<p-inputNumber inputId="igv" [disabled]="true" prefix="S/. " [(ngModel)]="ventaDb.igv"></p-inputNumber>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="d-flex justify-content-end align-items-center">
						<label for="total__id" class="pr-1">Importe Total</label>
						<span class="c--importe_total--class">S/. {{ventaDb.monto_total}}</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label for="venta_detalles__id">Detalles de venta (No sale en el comprobante)</label>
						<textarea inputId="venta_detalles__id" pInputTextarea [(ngModel)]="ventaDb.detalles" rows="1"></textarea>
					</div>
				</div>
			</div>
		</p-card>
	</div>
	<div class="col-md-4">
		<p-card>
			<div class="row">
				<div class="col-lg-4">
					<h4>Comprobante</h4>
				</div>
				<div class="col-lg-8">
					<p-inputSwitch [(ngModel)]="estado_comprobante"></p-inputSwitch>
				</div>
			</div>
			<div class="main-formulario" *ngIf="estado_comprobante">
				<div class="row">
					<div class="col-md-4">
						<label for="cliente">Tipo Comprobante:</label>
						<p-dropdown id="cliente" [options]="tipos_comprobante" optionValue="id_tipo_comprobante" optionLabel="nombre_tipo_comprobante" [showClear]="true" [(ngModel)]="ventaDb.tipo_comprobante" placeholder="Comprobante"></p-dropdown>
					</div>
					<div class="col-md-7">
						<div class="row p-0">
							<div class="col-lg-4 pr-0">
								<label for="comprobante_serie__id">Serie:</label>
								<input inputId="comprobante_serie__id" pInputText [(ngModel)]="ventaDb.serie_comprobante" class="small-input">
							</div>
							<div class="col-lg-1 p-0 m-0 text-center fs-2 d-inline align-middle lh-1 p-1 fw-bold">
								<br>
								-
							</div>
							<div class="col-md-7 pl-0">
								<label for="comprobante_numero__id">Numero:</label>
								<p-inputNumber inputId="comprobante_numero__id" [(ngModel)]="ventaDb.numero_comprobante" class="small-input" [useGrouping]="false"></p-inputNumber>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<label for="">Fecha</label>
						<p-calendar [(ngModel)]="ventaDb.fecha_comprobante" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
					</div>
					<div class="col-md-6">
						<label for="">Monto Total:</label>
						<input type="text" pInputText [(ngModel)]="ventaDb.monto_total" class="small-input c--comprobante_total_venta--class" [readonly]="true">
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<div class="form-group">
							<label for="venta_detalles__id">Detalles de comprobante</label>
							<textarea inputId="venta_detalles__id" pInputTextarea [(ngModel)]="ventaDb.detalles_comprobante" rows="1"></textarea>
						</div>
					</div>
				</div>
			</div>
		</p-card>
	</div>
</div>

<div class="row">
	<div class="col-lg-12 text-end">
		<p-button label="Guardar cambios" [style]="{ 'display': 'inline-block', 'width': '300px', 'font-size': '20px', 'border-radius': '10px', 'padding': '10px' }" size="large" (onClick)="update__venta()"></p-button>
	</div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { AlmacenProductoReporte_Response } from 'src/app/response/almacen-producto/almacen_producto_reporte.request';
import { CardModule } from 'primeng/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { Subject } from 'rxjs';
import { Almacen_Service } from 'src/app/services/almacen.service';
import { ProductoService } from 'src/app/services/producto.service';
import {  alert_success, alert_warning } from 'src/app/functions/alert-function';
import { InputNumberModule } from 'primeng/inputnumber';

interface Talla {
	id: number;
	name: string;
}

@Component({
	selector: 'app-registro-almacen-producto',
	standalone: true,
	imports: [ReactiveFormsModule, DropdownModule,
		FormsModule, CardModule, FontAwesomeModule, InputNumberModule, CommonModule, InputTextModule, InputTextareaModule, ButtonModule],
	templateUrl: './registro-almacen-producto.component.html',
	styleUrl: './registro-almacen-producto.component.css',
	providers: [
	]
})
export class RegistroAlmacenProductoComponent implements OnInit {
	faTableList = faTableList;
	@Input() title: string = "";
	@Input() item: AlmacenProductoReporte_Response = new AlmacenProductoReporte_Response();
	@Output() closeModalEmmit = new EventEmitter<boolean>();

	eventsSubject: Subject<void> = new Subject<void>();
	formVisible = false;
	disableButton: boolean = false;
	yaRegistrado: boolean = false;

	/* VAriables que sirven para enviar datos del TS al HTML mediante [options] */
	almacenes: any[] = [];
	categorias_producto: any[] = [];
	productos: any[] = [];

	tallas: Talla[] | undefined;
	selectTalla: Talla | undefined;

	/* Variables que sirven para recibir datos desde del HTML al TS mediange ([ngModel]) */
	almacen: any = {};
	categoria_producto: any = {};
	almacen_producto: any = {
		precio: '',
		unidad_medida: 'UNIDAD',
		caracteristicas: `{ "TALLA": "S", "COLOR": "ROJO" }`
	};

	constructor(
		private _Almacen_Service: Almacen_Service,
		private _CategoriaService: CategoriaService,
		private _Producto_Service: ProductoService,
		private _AlmacenProducto_Service: AlmacenProducto_Service
	) {
	}

	ngOnInit(): void {
		this.getAlmacenes_Api();
		this.getCategorias_Api();
		this.tallas = [
			{ id: 0, name: "0000" },
			{ id: 0, name: "000" },
			{ id: 0, name: "00" },
			{ id: 1, name: "0" },
			{ id: 2, name: "2" },
			{ id: 3, name: "4" },
			{ id: 4, name: "6" },
			{ id: 5, name: "8" },
			{ id: 6, name: "10" },
			{ id: 7, name: "12" },
			{ id: 8, name: "14" },
			{ id: 9, name: "16" },
			{ id: 10, name: "28" },
			{ id: 11, name: "30" },
			{ id: 12, name: "32" },
			{ id: 13, name: "34" },
			{ id: 14, name: "36" },
			{ id: 15, name: "38" },
			{ id: 16, name: "40" },
			{ id: 17, name: "42" },
			{ id: 18, name: "44" },
			{ id: 19, name: "S" },
			{ id: 20, name: "M" },
			{ id: 21, name: "L" },
			{ id: 22, name: "XL" },
			{ id: 23, name: "XXL" },
			{ id: 23, name: "XXXL" },
			{ id: 24, name: "Liga" },
			{ id: 25, name: "Amarra" },
			{ id: 26, name: "Michi" },
			{ id: 27, name: "Varón/36" },
			{ id: 28, name: "Varón/S" },
			{ id: 29, name: "Varón/M" },
			{ id: 30, name: "Varón/L" },
			{ id: 31, name: "Dama/36" },
			{ id: 32, name: "Dama/S" },
			{ id: 33, name: "Dama/M" },
			{ id: 34, name: "Dama/L" },
			{ id: 35, name: "Buzo/2" },
			{ id: 36, name: "Buzo/3" },
			{ id: 37, name: "Buzo/4" },
			{ id: 38, name: "Uniforme/2" },
			{ id: 39, name: "Uniforme/3" },
			{ id: 40, name: "Uniforme/4" },
			{ id: 41, name: "7" },
			{ id: 42, name: "9" },
			{ id: 43, name: "Buzo" },
			{ id: 44, name: "Uniforme" },
			{ id: 45, name: "P" },
			{ id: 46, name: "S" },
			{ id: 47, name: "3" },
			{ id: 48, name: "1" },
			{ id: 49, name: "5" },
			{ id: 50, name: "Inicial" },
			{ id: 51, name: "Primaria" },
			{ id: 52, name: "Secundaria" },

		]
	}

	closeModal(res: boolean) {
		this.closeModalEmmit.emit(res);
	}

	formatPrice() {
		const value = parseFloat(this.almacen_producto.precio);
		if (!isNaN(value)) {
			this.almacen_producto.precio = value.toFixed(2);
		}
	}

	getAlmacenes_Api(): void {
		const request_data = {
			usuario: localStorage.getItem('id_usuario')
		}
		this._Almacen_Service.getByOther( request_data ).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.almacenes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getCategorias_Api(): void {
		this._CategoriaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.categorias_producto = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getProductosByCategoriaId_Api(categoria_producto: DropdownChangeEvent): void {
		const categoria_producto_id = categoria_producto.value;
		const request_data = {
			categoria: categoria_producto_id
		};
		this._Producto_Service.getByOther(request_data).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getProductoById_Api(producto: DropdownChangeEvent): void {
		const id_producto = producto.value;
		this._Producto_Service.getByid(id_producto).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.almacen_producto.nombre = response_data.nombre
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	registerAlmacenProducto_Api(): void {
		const request = {
			almacen_id: (this.almacen.id_almacen != undefined) ? this.almacen.id_almacen : null,
			producto_id: (this.almacen_producto.id_producto != undefined) ? this.almacen_producto.id_producto : null,
			nombre: (this.almacen_producto.nombre != undefined) ? this.almacen_producto.nombre : null,
			nombre_corto: (this.almacen_producto.nombre_corto != undefined) ? this.almacen_producto.nombre_corto : null,
			nombre_largo: (this.almacen_producto.nombre_largo != undefined) ? this.almacen_producto.nombre_largo : null,
			unidad_medida: (this.almacen_producto.unidad_medida != undefined) ? this.almacen_producto.unidad_medida : null,
			cantidad: (this.almacen_producto.cantidad != undefined) ? this.almacen_producto.cantidad : null,
			precio: (this.almacen_producto.precio != undefined) ? this.almacen_producto.precio : null,
			descuento: 0,
			caracteristicas: (this.almacen_producto.caracteristicas) ? this.almacen_producto.caracteristicas : null,
			estado: 2,
			detalles: (this.almacen_producto.detalles) ? this.almacen_producto.detalles : null
		};



		//console.log(request);
		if (
			request.almacen_id == null ||
			request.producto_id == null ||
			request.nombre == null ||
			request.nombre_corto == null ||
			request.nombre_largo == null ||
			request.unidad_medida == null ||
			request.cantidad == null ||
			request.precio == null || isNaN(request.precio) || request.precio <= 0 || 
			request.descuento == null ||
			request.caracteristicas == null ||
			request.estado == null
		) {
			alert_warning("ERROR: Asegúrese De rellenar todos los campos correctamente ");
			return;
		}
		
		// Si pasó todas las validaciones, procede con el registro
		this._AlmacenProducto_Service.create(request).subscribe({

			next: (response: any) => {

				const code_error = response.type;
				switch (code_error) {
					case 'NOT_FOUND_CODE':
						alert_warning("No se encontró código disponible para el producto");
						break;
					case 'DUPLICADO':
						alert_warning("El producto ya fue asignado al almacén");
						break;
					default:
						//console.log(code_error);
						alert_success("Registrado de forma correcta");
						this.closeModalEmmit.emit(true);
						this.disableButton = false;
						this.closeModal(true);
						break;
				}
				



			},
			error: (err) => {
				console.error("Error al registrar el producto:", err); // Verifica el error en la consola

				this.disableButton = false;
			}
		});
	}


	onTallaChange(event: any) {
		if (this.selectTalla) {
			this.almacen_producto.nombre_corto = this.selectTalla.name;
			// Actualizar nombre largo concatenando el nombre y el nombre corto
			this.almacen_producto.nombre_largo = this.almacen_producto.nombre + ' ' + this.almacen_producto.nombre_corto;
			// Actualizar el contenido del textarea
			this.almacen_producto.caracteristicas = `{"TALLA": "${this.selectTalla.name}", "COLOR": "${this.almacen_producto.caracteristicas.COLOR || ''}"}`;
		} else {
			this.almacen_producto.nombre_corto = null; // Otra acción por defecto si no se selecciona ninguna talla
			this.almacen_producto.nombre_largo = this.almacen_producto.nombre; // Resetear nombre largo si no hay talla seleccionada

		}
	}



}

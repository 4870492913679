import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CrudService } from './util/crud.service';
import { urlConstants } from '../constants/url-constants';
import { behaidorInterface } from './interfaces/behaidor-interface';

import { Cliente_Response } from '../response/cliente/cliente-response';
import { Cliente_Request } from '../request/cliente/cliente-request';



@Injectable({
  providedIn: 'root'
})
export class Cliente_Service extends CrudService<Cliente_Request,Cliente_Response>implements behaidorInterface<Cliente_Response[]>{
  private dataSource = new BehaviorSubject<Cliente_Response[]>([]);

  constructor(
    protected http: HttpClient,
  ) { 
    super(http, urlConstants.cliente);
  }
  setValueBehaidor(value: Cliente_Response[]): void {
    this.dataSource.next(value);
  }
  getValueBehaidorSubject()  {
    return this.dataSource.getValue();
  }
  clearBehaidorSubject(): void {
    this.dataSource.next([]);
  }
}

<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ title }}</h4>
</div>

    <p-card>
        <div class="row">
            <div class="col-lg-4">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Selecciona Almacén" inputId="almacen__id" [options]="almacenes" optionValue="id_almacen" optionLabel="nombre" [(ngModel)]="almacen.id_almacen"></p-dropdown>
                    <label for="almacen__id">Almacén</label>
                </span>
            </div>
            <div class="col-lg-4">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Selecciona Categoría" inputId="categoria_producto__id" [options]="categorias_producto" optionValue="id_categoria_producto" optionLabel="nombre" (onChange)="getProductosByCategoriaId_Api($event)" [(ngModel)]="categoria_producto.id_categoria_producto"></p-dropdown>
                    <label for="categoria_producto__id">Categoría</label>
                </span>
            </div>
            <div class="col-lg-4">
                <span class="p-float-label">
                    <p-dropdown optionLabel="name" [showClear]="true"
                    placeholder="Selecciona Producto" inputId="float-label--producto" [options]="productos" optionValue="id_producto" optionLabel="nombre_largo" [(ngModel)]="almacen_producto.id_producto" (onChange)="getProductoById_Api($event)"></p-dropdown>
                    <label for="float-label--producto">Producto</label>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-8">
                <span class="p-float-label">
                    <input pInputText id="nombre__id" class="form-control" [(ngModel)]="almacen_producto.nombre" >
                    <label for="nombre__id">Nombre</label>
                </span>
            </div>
            <div class="col-lg-4">
                <span class="p-float-label">
                    <!-- <input pInputText id="nombre_corto__id" class="form-control" [(ngModel)]="almacen_producto.nombre_corto" > -->
                    <p-dropdown class="button-select" [options]="tallas" [(ngModel)]="selectTalla" optionLabel="name"
                    [showClear]="true " [filter]="true" (ngModelChange)="onTallaChange($event)" placeholder="Seleccione Almacén"></p-dropdown>
                    <label for="nombre_corto__id">Talla</label>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4">
                <span class="p-float-label">
                    <input pInputText id="unidad_medida__id" class="form-control" [(ngModel)]="almacen_producto.unidad_medida">
                    <label for="unidad_medida__id">U Medida</label>
                </span>
            </div>
            <div class="col-lg-4">
                <span class="p-float-label">
                    <input pInputText id="cantidad__id" type="number" class="form-control" [(ngModel)]="almacen_producto.cantidad" >
                    <label for="cantidad__id">Cantidad/Stock inicial</label>
                </span>
            </div>
            <div class="col-lg-4">
                <span class="p-float-label">
                    <input pInputText id="precio__id" class="form-control" [(ngModel)]="almacen_producto.precio"
                        pattern="^\d+(\.\d{2,})?$" title="Debe ser un número con al menos dos decimales" (blur)="formatPrice()" />
                    <label for="precio__id">Precio</label>
                </span>
                
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-12">
                <span class="p-float-label">
                    <textarea id="detalles__id" class="form-control" pInputTextarea placeholder="" [(ngModel)]="almacen_producto.detalles" ></textarea>
                    <label for="detalles__id">Detalles</label>
                </span>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-12">
                <span class="p-float-label">
                    <textarea id="caracteristicas__id" class="form-control" pInputTextarea placeholder="" [(ngModel)]="almacen_producto.caracteristicas" placeholder="" value="{ 'COLOR': 'ROJO', 'TALLA': 'ROJO' }">{ "TALLA": "S", "COLOR": "ROJO" }</textarea>
                    <label for="caracteristicas__id">Características</label>
                </span>
            </div>
        </div>
        
        <div class="row mt-5">
            <div class="col-lg-12 text-end">
                <div class="register-botton">
                    <button pButton type="button" class="p-button-success ml-1" icon="pi pi-save"
                    label="Registrar" (click)="registerAlmacenProducto_Api()"></button>
                    <p-button label="Cancelar" (click)="closeModal(true)"  [raised]="true" severity="danger"></p-button>
                </div>
            </div>
        </div>
    </p-card>

    


export class ProductRequest {
    id_producto: number;
    categoria_producto_id: number;
    codigo: string;
    nombre: string;
    nombre_corto: string;
    nombre_largo: string;
    estado: number;
    
    constructor(){
        this.id_producto = 0;
        this.categoria_producto_id = 0 ;
        this.codigo = "" ;
        this.nombre = "";
        this.nombre_corto = "";
        this.nombre_largo = "";
        this.estado = 0;  
    
}
}
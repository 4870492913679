

<div class="card flex gap-2" id="carta_1">
	<div class="w-9 mb-5">
		<div class="px-gutter pt-6 pb-20 bg-slate-900 flex-none">
			<div class="flex items-center justify-between" vexContainer>
				<h2 class="headline text-neutral-100 m-0 flex items-center w-full max-w-xxxl mr-6">
					<fa-icon [icon]="faTableList" class="hidden sm:block ml-5 fa-2xl"></fa-icon>
					<span class="ml-4 block pt-0">Movimiento  Almacén</span>
				</h2>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div class="card text-dark bg-light mb-3">
			<div class="card-body">
				<!-- <app-generic-filter [filters]="filterForm" (resFilter)="receibeFilters($event)"></app-generic-filter> -->

				<!-- falta la tabla paginada -->
				<app-table-pagging [cols]="cols" [data]="IngresosAlmacen_Response" [totalRecords]="totalRecords" [loading]="loading"
					[events]="eventsSubject.asObservable()" [button_actions]="buttonActions"
					(Button_Emit)="recibeAction($event, template, ConfigUser)">
				</app-table-pagging>

			</div>
		</div>
	</div>

	<ng-template #template>
		<app-movimiento-almacen (closeModalEmmit)="closeModal($event)" [title]="titleModal"></app-movimiento-almacen>
	</ng-template>
	<ng-template #ConfigUser></ng-template>
</div>
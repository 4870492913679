
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { CommonModule } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CategoriaService } from 'src/app/services/categoria.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { ProductoService } from 'src/app/services/producto.service';
import { Subject, Subscription, empty } from 'rxjs';
import { Almacen_Service } from 'src/app/services/almacen.service';
import { IngresoAlmacen_Response } from 'src/app/response/ingreso_almacen/ingreso_almacen.response';
import { Almacen_Response } from 'src/app/response/almacen/almacen.response';
import { CategoriaResponse } from 'src/app/response/categorias/categoria.respose';
import { ProductResponse } from 'src/app/response/productos/producto.response';
import { Proveedor_Response } from 'src/app/response/proveedor/proveedor.response';
import { IngresoAlmacen_Request } from 'src/app/request/ingreso_almacen/ingreso_almacen.request';
import { IngresoAlmacen_Service } from 'src/app/services/ingreso_almacen.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { alert_error, alert_success } from 'src/app/functions/alert-function';
import { DialogModule } from 'primeng/dialog';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { Unidadmedida } from 'src/app/common/unidad_medida';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
	selector: 'app-registro-ingreso-almacen',
	standalone: true,
	imports: [CardModule, InputNumberModule, TableModule, DropdownModule, ButtonModule, FormsModule, InputTextModule, TooltipModule, DividerModule, CommonModule, SelectButtonModule, FontAwesomeModule, DialogModule, FormsModule],
	templateUrl: './registro-ingreso-almacen.component.html',
	styleUrl: './registro-ingreso-almacen.component.css',
	providers: [
		CategoriaService,
		Almacen_Service,
		ProveedorService,
		IngresoAlmacen_Service
	]
})
export class RegistroIngresoAlmacenComponent implements OnInit {
	faTableList = faTableList;
	@Input() title: string = "";
	@Input() item: IngresoAlmacen_Response = new IngresoAlmacen_Response();
	@Output() closeModalEmmit = new EventEmitter<boolean>();
	disableButton: boolean = false;

	IngresoAlmacen_Form: FormGroup;
	Button_Disabled: boolean = false;
	eventsSubject: Subject<void> = new Subject<void>();
	formVisible = false;

	/*select unidad de medida */
	unidad_medida: Unidadmedida[] | undefined;

	Select_Unidad_Medida: Unidadmedida | undefined;
	IngresoAlmacen_Response: IngresoAlmacen_Response[] = [];
	Almacen_Response: Almacen_Response[] = [];
	CategoriaProducto_Response: CategoriaResponse[] = [];
	Producto_Response: ProductResponse[] = [];
	ProveedorResponse: Proveedor_Response[] = [];

	request: IngresoAlmacen_Request = new IngresoAlmacen_Request();

	constructor(
		private fb: FormBuilder,
		private _AlmacenService: Almacen_Service,
		private _CategoriaService: CategoriaService,
		private _ProductoService: ProductoService,
		private _AlmacenProductoService: AlmacenProducto_Service,
		private _ProveedorService: ProveedorService,
		private _IngresoAlmacen: IngresoAlmacen_Service,
	) {
		this.IngresoAlmacen_Form = this.fb.group({
			almacen_id: [null, [Validators.required]],
			proveedor_id: [null, [Validators.required]],
			productos: [],
		});
	}


	get f() { return this.IngresoAlmacen_Form.controls; }


	ngOnInit(): void {
		this.getAlmacenes_Api();
		this.getCategorias_Api();
		this.getProveedores_Api();
		this.getProductos_Api();
		this.Almacen_Response = this._AlmacenService.getValueBehaidorSubject();
		this.IngresoAlmacen_Form.patchValue(this.item);
		this.IngresoAlmacen_Response = [];
		this.IngresoAlmacen_Response.push(this.item);
		this._IngresoAlmacen.setValueBehaidor(this.IngresoAlmacen_Response);
		this.IngresoAlmacen_Form.controls["almacen_id"].setValue(this.item.almacen_id == 0 ? null : this.item.almacen_id);

		this.unidad_medida = [
			{ name: 'UNIDAD', code: 'UNIDAD' },
		];
	}
	closeModal(res: boolean) {
		this.closeModalEmmit.emit(res);
	}

	stateOptions = [{ label: 'No', value: 'NO' }, { label: 'Sí', value: 'SI' }];
	incluir_proveedor: string = 'NO';

	suscription: Subscription;
	// productos: any[] = [];
	almacenes: any[] = [];
	categorias_dinamico: any[][] = [];
	proveedores: any[] = [];
	productos_dinamico: any[] = [];
	loading: boolean = true;

	almacen: any = {};
	proveedor: any = {};
	cliente: any = {};

	/* Enviar datos del TS AL HTML (DB) */
	productos_dinamico__db: any[][] = [];
	almacen_productos_dinamico__db: any[] = []; // array de pobjetos


	///////////////////////////




	getAlmacenes_Api(): void {
		const request_data = {
			usuario: localStorage.getItem('id_usuario')
		}
		this._AlmacenService.getByOther( request_data ).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.almacenes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getProveedores_Api(): void {
		this._ProveedorService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.proveedores = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getCategorias_Api(): void {
		this._CategoriaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.categorias_dinamico = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getProductos_Api(): void {
		this._ProductoService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos_dinamico__db = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductosByProductoIdAlmacenId_Api(producto: DropdownChangeEvent, i: number): void {
		const producto_id = producto.value;
		const almacen_id = this.almacen.id_almacen;

		const request_data = {
			producto: producto_id,
			almacen: almacen_id
		}
		this._AlmacenProductoService.getByOther(request_data).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				//console.log(request_data)
				if (response_data) {
					// this.categorias_dinamico = response_data;   
					this.almacen_productos_dinamico__db[i] = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se 1encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductoById_Api(almacen_producto: DropdownChangeEvent, i: number): void {
		const almacen_producto_id = almacen_producto.value;

		this._AlmacenProductoService.getByid(almacen_producto_id).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos[i].precio_venta = response_data.precio;
					this.productos[i].unidad_medida = response_data.unidad_medida;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se 1encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}


	getProveedorById_Api(proveedor: DropdownChangeEvent): void {
		const id_proveedor = proveedor.value;
		this._ProveedorService.getByid(id_proveedor).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.proveedor = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}


	//filas que agregar capturar los campos
	productos: any[] = [];

	//agregar filas en el dom de la tabla
	agregarFila() {
		const nuevaFila = { categoria: '', id_almacen_producto: '', unidad_medida: '', cantidad: '', precio_compra: '', precio_venta: '', codigo: '', detalles: '' };
		this.productos.push(nuevaFila);
	}
	//elimina una fila depende al index de la tabla en este caso elimina 1
	eliminarFila(index: number) {
		this.productos.splice(index, 1);
	}

	registerProductoAlmacen_Api(): void {
		const request = {
			id_ingreso: null,
			almacen_id: (this.almacen.id_almacen != undefined) ? this.almacen.id_almacen : null,
			proveedor_id: (this.proveedor.id_proveedor != undefined) ? this.proveedor.id_proveedor : null,
			codigo: null,
			detalles: null,
			productos: JSON.parse(JSON.stringify(this.productos))
		}

		//console.log(request);
		if (request.almacen_id == null || request.productos.length == 0) {
			alert_error("ERROR", "Asegúrese de rellenar todos los campos");
			return;
		} else {
			this._IngresoAlmacen.create(request).subscribe({
				next: (response: any) => {
					alert_success("Registrado de forma correcta", 5000);
					this.closeModalEmmit.emit(true);
					this.disableButton = false;
					this.closeModal(true);

				},
				error: (err) => {
					this.disableButton = false;
				}
			});
		}

	}

}

<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('UNIFORME TAEKWONDO')">UNIFORME TAEKWONDO</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('GUARDAPOLVO')">GUARDAPOLVO</button>

<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('SHORT')">SHORT</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('SNIKER')">SNIKER</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('SOMBREROS')">SOMBREROS</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('PULLOVER')">PULLOVER</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('BUZO DOCENTE NUEVO')"> BUZO DOCENTE NUEVO</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('BUZO DOCENTE ANTIGUO')"> BUZO DOCENTE ANTIGUO</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('CASACA')"> CASACA</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('CUADERNO')"> CUADERNO</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('BUZO ALUMNO')">  BUZO ALUMNO</button> 
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('FALDA')"> FALDA</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('PANTALÓN')"> PANTALÓN</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('BUFANDA')"> BUFANDA</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('POLERA ALUMNO')"> POLERA ALUMNO</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('CORBATA')">CORBATA</button>
<button pButton class="button-filter" type="button" (click)="filtrarPorNombre('POLO MANGA LARGA')">POLO MANGA LARGA</button>



<div class="card">
    <!-- Campo de búsqueda -->
    
 
    <div class="row">
        <div class="col-md-3" *ngFor="let product of filteredProducts">
            <p-card [style]="{ 'margin-bottom': '20px' }">
                <ng-template pTemplate="content">
                    <div *ngIf="product">
                        <p class="nombre_product"> {{ product.nombre }}</p>
                        <p class="talla_producto">Talla: {{ product.nombre_corto }}</p>
                        <p [ngClass]="{
                            'cantidad_producto': true,
                            'cantidad-cero': product.cantidad === 0 || product.cantidad === 1,
                            'cantidad-amarillo': product.cantidad && (product.cantidad >= 2 && product.cantidad <= 5)
                        }">
                            Cantidad: {{ product.cantidad }}
                        </p>
                        <p class="cantidad_producto">Almacen: {{ product.nombre_almacen }}</p>
                    </div>
                </ng-template>
                
                
                
            </p-card>
        </div>
    </div>
    

    <!-- Paginator para navegar entre las páginas de productos -->
    <p-paginator [rows]="itemsPerPage"  [rowsPerPageOptions]="[5, 10, 20]" [totalRecords]="filteredProducts.length" [first]="first" (onPageChange)="onPageChange($event)"></p-paginator>
</div>

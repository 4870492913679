import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder,  FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';
import { ProductResponse } from 'src/app/response/productos/producto.response';
import { ProductoService } from 'src/app/services/producto.service';
import { CardModule } from 'primeng/card';
import { CategoriaResponse } from 'src/app/response/categorias/categoria.respose';
import { CategoriaService } from 'src/app/services/categoria.service';
import { ProductRequest } from 'src/app/request/producto/producto.request';
import { statesConstant_ActiveInactiveFilter } from 'src/app/constants/estado-constats';

interface Categoria {
  id : number;
  name: string;
}

@Component({
  selector: 'app-producto-register',
  standalone: true,
  imports: [FontAwesomeModule,TableModule, CardModule,CommonModule, ReactiveFormsModule, ButtonModule, DialogModule, FormsModule, PaginatorModule, InputTextModule],
  templateUrl: './producto-register.component.html',
  styleUrl: './producto-register.component.css',

})
export class ProductoRegisterComponent implements OnInit {
  @Input() title: string = "";
  @Input() item: ProductResponse = new ProductResponse();
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  myForm: FormGroup;
  disableButton: boolean = false;
  listStatus = statesConstant_ActiveInactiveFilter;
  categorias : Categoria[] | undefined;

  categoriasSelected: Categoria | undefined;
  Categoria : CategoriaResponse[] = [];
  Productos : ProductResponse[] = [];
  request: ProductRequest = new ProductRequest();
  constructor(
    private fb: FormBuilder,
    private _ProductoService: ProductoService,
    private _CategoriaService : CategoriaService
  ) {
    this.myForm = this.fb.group({
      id_producto: [{ value: 0, disabled: true }, [Validators.required]],
      categoria_producto_id: [ [Validators.required]],
      nombre: [1, [Validators.required]],
      nombre_corto: [1, [Validators.required]],
      nombre_largo: [1, [Validators.required]]
    });
  }
  eventsSubject: Subject<void> = new Subject<void>();

  get f() { return this.myForm.controls; }
  ngOnInit(): void {

    this.categorias = [
      {id:1, name:"ACTIVO"},
      {id:2, name: "INACTIVO"},
   
     // {id:3, name: "PENDIENTE"},
    ]
    this.getCategories();
    this.Categoria = this._CategoriaService.getValueBehaidorSubject();
    this.myForm.patchValue(this.item);
    this.Productos=[];
    this.Productos.push(this.item);
    this._ProductoService.setValueBehaidor(this.Productos);
    this.myForm.controls["categoria_producto_id"].setValue(this.item.categoria_producto_id == 0 ? null : this.item.categoria_producto_id);
  }
  closeModal(res: boolean) {
    this.closeModalEmmit.emit(res);
  }
  ngSave() {
    
    this.disableButton = true;
    let _item = this.myForm.getRawValue();
 
    this.item = _item;
    if (this.item.id_producto == 0) {

      this.create();
    }
    else {
      this.update();
    }
  }
  create() {
    this._ProductoService.create(this.item).subscribe({
      next: (data: any) => {
        this.item = data;
        alert_success("Registrado de forma correcta");
        this.closeModalEmmit.emit(true);
        this.disableButton = false;
        this.closeModal(true);
      },
      error: (err) => {
        alert_warning("complete los campos")
        this.disableButton = false;
      }
    });
  }
  update() {
    const productId = this.item.id_producto; // Supongo que el ID está presente en tu objeto 'item'
    
    this._ProductoService.update(productId, this.item).subscribe({
      next: (data: any) => {
        this.item = data;
        alert_success("Actualizado de forma correcta");
        this.closeModalEmmit.emit(true);
        this.disableButton = false;
        this.closeModal(true);
      },
      error: (err) => {
        alert_warning("complete los campos")
        this.disableButton = false;
      
      }
    });
  }

  getCategories() {
    this._CategoriaService.get().subscribe({
      next: (response: any) => {   
        const response_data = response.data;
        if (response_data) {
          this.Categoria = response_data;   
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró un array de productos en la respuesta.');
        }
      },
      error: (error) => {
        console.log("Error al llamar el servicio", error);
      }
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';

import { CrudService } from './util/crud.service';

import { behaidorInterface } from './interfaces/behaidor-interface';
import { urlConstants } from '../constants/url-constants';
import { Ventas_Request } from '../request/ventas/ventas-request';
import { Ventas_Response } from '../response/ventas/ventas-response';

    
@Injectable({
	providedIn: 'root',
})
export class Venta_Service
	extends CrudService<Ventas_Request, Ventas_Response>
	implements behaidorInterface<Ventas_Response[]>
{
	private dataSource = new BehaviorSubject<Ventas_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.ventas);
	}

	setValueBehaidor(value: Ventas_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
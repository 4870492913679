import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_success } from 'src/app/functions/alert-function';
import { Cliente_Response } from 'src/app/response/cliente/cliente-response';
import { Cliente_Service } from 'src/app/services/cliente.service';


@Component({
  selector: 'app-register-cliente',
  standalone: true,
  imports: [CardModule, ButtonModule, ReactiveFormsModule, FormsModule, InputTextModule, DropdownModule],
  templateUrl: './register-cliente.component.html',
  styleUrl: './register-cliente.component.css'
})
export class RegisterClienteComponent {
  @Input() title: string = "";
  @Input() item: Cliente_Response = new Cliente_Response();
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  myForm: FormGroup;
  disableButton: boolean = false;
  Clientes: Cliente_Response[] = [];
  selectDocumento: number | null = null;


  documentos = [
    { value: 1, label: 'DNI' },
    { value: 2, label: 'Pasaporte' }
  ]
  

  constructor(
    private fb: FormBuilder,
    private _Clienteservice: Cliente_Service,

  ) {
    this.selectDocumento = 1;
    this.myForm = this.fb.group({
      
      tipo_documento: [1],
      numero_documento: [""],
      apellido_paterno: [""], 
      apellido_materno: [""],
      nombres: [null,[Validators.required]],

    });
  }
  eventsSubject: Subject<void> = new Subject<void>();

  get f() { return this.myForm.controls; }
  ngOnInit(): void {

  }
  closeModal(res: boolean) {
    
    this.closeModalEmmit.emit(res);
  }
  ngSave() {
    this.disableButton = true;
    const tipoDocumentoValue = this.myForm.get('tipo_documento')?.value;
    
  
    // Asignar directamente el valor del formulario al item
    this.item = { ...this.myForm.value };
  
    this.create();
  }
  
  create() {
    this._Clienteservice.create(this.item).subscribe({
      next: (data: any) => {
        this.item = data;
        alert_success("Registrado de forma correcta");
        this.closeModalEmmit.emit(true);
        this.disableButton = false;
        this.closeModal(true);
      },
      error: (err) => {
        this.disableButton = false;
      }
    });
  }




}

<p-table [columns]="cols" [paginator]="true" [rows]="10" [pageLinks]="3" [responsive]="true"
  [rowsPerPageOptions]="[5, 10, 20]" [value]="data" [loading]="loading">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th pSortableColumn="{{ col.field }}" *ngFor="let col of columns">
        {{ col.header }}

      </th>
      <th class="text-right">

        <p-button [raised]="true" *ngIf="buttonAdd.active" (click)="addElement()" pTooltip="Agregar"
          tooltipPosition="bottom" icon="pi pi-plus" severity="success"></p-button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [ngClass]="
    {
      'bg-danger text-white': (rowData['id_status'] && (rowData['id_status'].toString() != '1' && rowData['id_status'].toString() != 'Activo')
      || rowData['id_estatus'] && (rowData['id_estatus'].toString() != '1' && rowData['id_estatus'].toString() != 'Activo')
      || rowData['id_status_show'] && (rowData['id_status_show'].toString() != '1' && rowData['id_status_show'].toString() != 'Activo')
      )


    }">
      <td *ngFor="let col of columns">
        <span *ngIf="col.field == 'idEstado' ">
          <!-- <app-status-label [value]="rowData[col.field]"></app-status-label> -->
        </span>
        <span *ngIf="col.field != 'idEstado' ">
          {{ rowData[col.field] }}
        </span>
      </td>
      <td class="text-right">

        <p-button [raised]="true" *ngIf="buttonEdit.active" (click)="editElement(rowData)" pTooltip="Editar"
          tooltipPosition="bottom" icon="pi pi-pencil
        " severity="info"></p-button>

        

        <p-button [raised]="true" *ngIf="buttonConfig.active" (click)="configElement(rowData)" pTooltip="Imprimir" tooltipPosition="bottom" icon="pi pi-print" severity="warning">
        </p-button>

       
       
        <p-button [raised]="true" *ngIf="buttonDelete.active" (click)="deleteElement(rowData)" pTooltip="Eliminar"
          tooltipPosition="bottom" icon="pi pi-trash
        " severity="danger"></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="cols.length" style="color: red; font-weight: normal; text-align: center;">No se encontraro registros</td>
    </tr>
  </ng-template>
</p-table>
<!-- <p-paginator class="boton-paginador" [showCurrentPageReport]="true"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [rows]="rowsPaginatable"
  [totalRecords]="totalRecords" (onPageChange)="refreshData($event)">
</p-paginator> -->
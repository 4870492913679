export class AlmacenProductoReporte_Response {
    id_almacen?: number;
    nombre_almacen? : string | null;
    direccion_almacen? : string | null;
    id_almacen_producto? : number;
    nombre : string;
    nombre_corto? : string | null;
    nombre_largo? : string | null;
    unidad_medida? : string | null;
    cantidad? : number | null;
    precio? : number | null;
    descuento? : number | null;
    descuento_porcentaje? : number | null;
    url_imagen? : string | null;
    detalles? : string | null;

    constructor () {
        this.id_almacen = 0;
        this.nombre_almacen = null;
        this.direccion_almacen = null;
        this.id_almacen_producto = 0;
        this.nombre = "";
        this.nombre_corto =null;
        this.nombre_largo = null;
        this.unidad_medida = null;
        this.cantidad =0;
        this.precio = 0;
        this.descuento =0;
        this.descuento_porcentaje = 0;
        this.url_imagen = null;
        this.detalles = null;
    }
}
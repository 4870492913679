<br>
<div class=" card flex gap-2" id="carta_1">

  <div class="w-9 mb-5 d-flex justify-content-between">
    <div class="px-gutter pt-6 pb-20 bg-slate-900 flex-none">
      <div class="flex items-center justify-between" vexContainer>
        <h2 class="headline text-neutral-100 m-0 flex items-center w-full max-w-xxxl mr-6">
          <fa-icon [icon]="faTableList" class="hidden sm:block ml-5 fa-2xl"></fa-icon>
          <span class="ml-4 block pt-0">Reporte Venta</span>
        </h2>
      </div>

    </div>
    <div class="container-venta">
      <div class="column-1">
        <label class="label-1" for="">Reporte-Venta Fecha</label>
        <!-- <p-dropdown class="button-select" [options]="almacenes" [(ngModel)]="selectedAlmacen" optionLabel="name"
        [showClear]="true" placeholder="Seleccione Almacén"></p-dropdown> -->

        <p-dropdown class="button-select" [options]="almacenes_reporte_venta" optionValue="id_almacen" [(ngModel)]="almacen_reporte_fecha.id_almacen" optionLabel="nombre"
        [showClear]="true" placeholder="Seleccione Almacén"></p-dropdown>
        
        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"></p-calendar>
        <p-button [raised]="true" class="botton-imrprimir" (click)="imprimirReporteVentaPDF(template,ventaData)"  pTooltip="Imprimir"
                    tooltipPosition="bottom" icon="pi pi-print" severity="warning">
                </p-button>
      </div>
      <div class="button-container">
        <p-button class="registro1" icon="pi pi-cart-plus" label="Nueva Venta" (click)="navigateToRegistroIngreso()"
            [raised]="true" severity="success"></p-button>
    
        <p-button class="registro1" icon="pi pi-box" label="Ver  Alerta Stock" (click)="navigateStockPage()"
            [raised]="true" severity="warning"></p-button>
    </div>
    
    </div>
    
    
    
  </div>
  <div class="container">
    <div class="card text-dark bg-light mb-3">

      <div class="card-body">

        <app-table-pagging [cols]="cols" [data]="Venta_Response" [totalRecords]="totalRecords" [loading]="loading"
          [events]="eventsSubject.asObservable()" [button_actions]="buttonActions"
          (Button_Emit)="recibeAction($event, template, ConfigUser,ventaDb)">



        </app-table-pagging>





      </div>
    </div>
  </div>

  <ng-template #template>

  </ng-template>
  <ng-template #ConfigUser></ng-template>

</div>
<div class="modal-header">
	<h4 id="h41" class="modal-title pull-left">{{ title }}</h4>

</div>
<div class="modal-body">
	<form [formGroup]="myForm">

		<p-card>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label for=""><strong>Id</strong></label>
						<input type="text" pInputText formControlName="id_producto" placeholder="Identificador" />
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<!-- <label for=""><strong>Categoria</strong></label>
				  <input type="text" pInputText formControlName="categoria_producto_id" placeholder="Ingrese Categoria" /> -->
						<label for=""> <strong>Categoria</strong> </label>
						<select formControlName="categoria_producto_id" class="form-select">
							<option value="null">Seleccione una opción</option>
							<option *ngFor="let categoria of Categoria" [value]="categoria.id_categoria_producto">
								{{ categoria.nombre_largo }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label for=""><strong>Nombre</strong></label>
						<input type="text" pInputText formControlName="nombre" placeholder="Ingrese nombre">
					</div>
				</div>
				<div class="col-md-6">
					<label for=""><strong>Nombre corto</strong></label><br>
					<input type="text" pInputText formControlName="nombre_corto" placeholder="Ingrese nombre_corto">
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<label for=""><strong>Nombre largo</strong></label> <br>
					<input type="text" pInputText formControlName="nombre_largo" placeholder="Ingrese nombre_largo" class="d-block">
				</div>
				<!-- <div class="col-md-6">
					<div class="form-group">
						<label for=""><strong>Estado</strong></label>
						<select formControlName="estado" class="form-select">
							<option *ngFor="let d of listStatus" [value]="d.value">
								{{ d.label }}
							</option>
						</select>
					</div>
				</div> -->
			</div>
			<!-- Otros campos aquí -->

			<div class="botones_input">
				<p-button label="Guardar" (click)="ngSave()" [disabled]="disableButton || myForm.invalid"
					[raised]="true" severity="success"></p-button>
				<p-button label="Cancelar" (click)="closeModal(false)" [raised]="true" severity="danger"></p-button>

			</div>
		</p-card>

	</form>
</div>
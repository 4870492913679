import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { ButtonAction } from 'src/app/common/button-action';
import { tableCol } from 'src/app/common/table-cols';
import { accionConst } from 'src/app/constants/general-constats';

@Component({
  selector: 'app-agregar-cliente-ventas',
  standalone: true,
  imports: [TableModule, CommonModule, ButtonModule, CardModule],
  templateUrl: './agregar-cliente-ventas.component.html',
  styleUrl: './agregar-cliente-ventas.component.css'
})
export class AgregarClienteVentasComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() cols: tableCol[] = [];
  @Input() data: any[] = [];
  @Input() totalRecords: number = 0;
  @Input() loading: boolean = true;
  @Input() events: Observable<void> = new Observable<void>();

  @Input() button_actions: ButtonAction[] = [
    { accion: accionConst.add, data: {}, active: true },
    { accion: accionConst.edit, data: {}, active: true },
    { accion: accionConst.delete, data: {}, active: true },
    { accion: accionConst.config, data: {}, active: true }
  ];

  @Output() changePage_Emit = new EventEmitter();
  @Output() Button_Emit = new EventEmitter();

  // rowsPaginatable: number = paggingConst.FILAS_PAGINADO;
  lazyLoading: boolean = false;

  buttonAdd: ButtonAction = new ButtonAction();
  buttonEdit: ButtonAction = new ButtonAction();
  buttonConfig: ButtonAction = new ButtonAction();
  buttonDelete: ButtonAction = new ButtonAction();


  private eventsSubscription: Subscription = new Subscription();

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.lazyLoading = true;
    }, 100);
  }

  ngOnInit(): void {

    this.eventsSubscription = this.events.subscribe(() => {
      this.loading = false;
    });
    this.assigmentButton();
  }

  assigmentButton() {
    this.buttonAdd = this.button_actions.filter(x => x.accion == accionConst.add)[0];

  }
  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  refreshData(d: any) {
    let nroPagina = d.page + 1;
    this.loading = true;
    setTimeout(() => {
      this.changePage_Emit.emit(nroPagina);
      this.loading = false;
    }, 100);
  }

  addElement() {
    console.log('Haciendo clic en addElement');
    let obj: any = { accion: accionConst.add, data: {} };
    this.Button_Emit.emit(obj);
  }






}

/*Constatntes de estados - */
export const statesConstant ={
    Activo: 1,
    Inactivo: 2,
    Elimininado: 3
}
export const statesConstant_ActiveInactive = [
    {
        id: 1,
        description: "Activo"
    },
    {
        id: 2,
        description: "Inactivo"
    },
    {
        id: 3,
        description: "suspendido"
    }

]
export const statesConstant_ActiveInactiveFilter = [
    {
        value: 1,
        label: "Activo"
    },
    {
        value: 2,
        label: "Inactivo"
    }
]

export const statesConstant_ActiveInactiveFilterTrueFalse = [
    {
        value: true,
        label: "Activo"
    },
    {
        value: false,
        label: "Inactivo"
    }
]

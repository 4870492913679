import { environment } from "src/environments/environment";



export const urlConstants = {
/*aqui va las rutas donde esta la api de back */
    producto: `${environment.ApiBack}producto/`,
    movimiento_almacen: `${environment.ApiBack}movimiento/`,
    categoria: `${environment.ApiBack}categorias/`,
    proveedor: `${environment.ApiBack}proveedor/`,
    almacen: `${environment.ApiBack}almacen/`,
    ventas: `${environment.ApiBack}venta/`,
    reporte_ventas: `${environment.ApiBack}reporte-ventas/`,
    cliente: `${environment.ApiBack}cliente/`,
    ingreso_almacen: `${environment.ApiBack}ingreso-almacen/`,
    almacen_producto: `${environment.ApiBack}almacen-producto/`,
    login: `${environment.ApiBack}user/login`
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CrudService } from './util/crud.service';
import { urlConstants } from '../constants/url-constants';
import { behaidorInterface } from './interfaces/behaidor-interface';

import { CategoriaRequest } from '../request/categoria/categoria.resquest';
import { CategoriaResponse } from '../response/categorias/categoria.respose';


@Injectable({
  providedIn: 'root'
})
export class CategoriaService extends CrudService<CategoriaRequest,CategoriaResponse>implements behaidorInterface<CategoriaResponse[]>{
  private dataSource = new BehaviorSubject<CategoriaResponse[]>([]);

  constructor(
    protected http: HttpClient,
  ) { 
    super(http, urlConstants.categoria);
  }
  setValueBehaidor(value: CategoriaResponse[]): void {
    this.dataSource.next(value);
  }
  getValueBehaidorSubject()  {
    return this.dataSource.getValue();
  }
  clearBehaidorSubject(): void {
    this.dataSource.next([]);
  }
}

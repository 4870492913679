import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CrudService } from './util/crud.service';
import { Almacen_Request } from '../request/almacen/almacen.request';
import { Almacen_Response } from '../response/almacen/almacen.response';
import { behaidorInterface } from './interfaces/behaidor-interface';
import { urlConstants } from '../constants/url-constants';
    
@Injectable({
	providedIn: 'root',
})

export class Almacen_Service
	extends CrudService<Almacen_Request, Almacen_Response>
	implements behaidorInterface<Almacen_Response[]>
{
	private dataSource = new BehaviorSubject<Almacen_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.almacen);
	}

	setValueBehaidor(value: Almacen_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}

import {  Component,  OnInit,  TemplateRef } from '@angular/core';
import { fadeInRight400ms } from 'src/assets/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/assets/animations/scale-in.animation';
import { stagger40ms } from 'src/assets/animations/stagger.animation';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProductoService } from 'src/app/services/producto.service';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { ProductoRegisterComponent } from '../producto-register/producto-register.component';
import { Subject } from 'rxjs';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ButtonAction } from 'src/app/common/button-action';
import { accionConst } from 'src/app/constants/general-constats';
import { ProductResponse } from 'src/app/response/productos/producto.response';
import { tableCol } from 'src/app/common/table-cols';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { CategoriaResponse } from 'src/app/response/categorias/categoria.respose';
import { CategoriaService } from 'src/app/services/categoria.service';
import { FilterTable } from 'src/app/common/filter-table';
import { FilterForm } from 'src/app/common/filter-form';
@Component({
  selector: 'app-prducto-list',
  standalone: true,
  imports: [FontAwesomeModule, ProductoRegisterComponent,TableModule,TablePaggingComponent, ProductoRegisterComponent, CommonModule, ButtonModule, DialogModule, FormsModule, PaginatorModule, InputTextModule],
  templateUrl: './producto-list.component.html',
  styleUrls: ['./producto-list.component.css'],
  animations: [
    stagger40ms,
    scaleIn400ms,
    fadeInRight400ms
  ],
  providers: [
    ProductoService,
    BsModalService,
  
  ],



})
export class ProductoListComponent implements OnInit {
  
  faTableList = faTableList;
  config = bsConfigModal;
  modalref:BsModalRef = new BsModalRef();
  totalRecords: number = 0;
  loading: boolean = false;
  titleModal: string ="";

  buttonActions: ButtonAction[]=[
    { accion: accionConst.add, data: {}, active: true },
    { accion: accionConst.edit, data: {}, active: true },
    { accion: accionConst.delete, data: {}, active: false },
    { accion: accionConst.config, data: {}, active: false }
  ];
  //librería rxjs ==> optimización de memoría
  eventsSubject: Subject<void> = new Subject<void>();
  valuesTable:ProductResponse[] = [];
  cols: tableCol[] = [
    { field: "id_producto", header: "ID" },
    { field: "nombre_largo_categoria", header: "Categoria" },
    { field: "codigo", header: "Codigo" },
    { field: "nombre", header: "Nombre" },
    { field: "nombre_corto", header: "Nombre Corto" },
    { field: "nombre_largo", header: "Nombre Largo" },
    { field: "estado", header: "Estado" }
   ];
   filterForm: FilterForm[] =[];
   productoSelected: ProductResponse = new ProductResponse();
   productos: ProductResponse [] = [];
   tCategoria: CategoriaResponse [] = [];
  constructor(
    
   private modalService: BsModalService,
    
    private _productoService: ProductoService,
    private _categoriaService : CategoriaService
  ){
  }

  ngOnInit(): void {
    this. getProducto() ;
    //console.log(ProductResponse.categoria_producto[0].nombre);
    // const categoriasObtenidas = /* ... */;
    // this.sharedService.setCategorias(categoriasObtenidas);
   // console.log("este",this.productoSelected)

  }


  getProducto() {
    this.loading = false;
    this._productoService.get().subscribe({
      next: (response: any) => {
        this.loading = true;
        //const categoria = response;
        const productosArray = this.findProductosArray(response);

        if (productosArray) {
          this.productos = this.adjustProductData(productosArray);
          console.log(this.productos) // Ajuste de datos aquí
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró un array de productos en la respuesta.');
        }
      },
      error: (error) => {
        this.loading = true;
        console.log("Error al llamar el servicio", error);
      }
    });
  }

  adjustProductData(products: ProductResponse[]): ProductResponse[] {
    // Ajusta los datos del estado según el valor
    return products.map(product => {
      product.estado = product.estado === 2 ? "Activo" : "Inactivo";
      product.categoria_producto_id = product.categoria_producto_id === 1 ? 2 : 1;

     
      
      
      return product;
    });
  }

  
  // Función para buscar el array de productos dentro de la respuesta
  findProductosArray(data: any): any[] | null {
  
    // Si la respuesta no es un array, busca en las propiedades
    for (const arr in data) {
      console.log("entrado a este for");
      if (Array.isArray(data[arr])) {
        // Si encuentra un array dentro de una propiedad, devuélvelo
        return data[arr];
      }
    }
    // Si no encuentra un array de productos, devuelve null
    return null;
  }
  
  
  changeFilter(name: string, array: FilterTable[]) {
    this.filterForm.map(x => {
      if (x.name == name) {
        x.data = array;
      }
    });
  }
  recibeAction(data: ButtonAction, template: TemplateRef<any>, templateconfigUser: TemplateRef<any>) {
    this.productoSelected = data.data;
    switch (data.accion) {
      case accionConst.add:
        this.productoSelected = new ProductResponse();
        this.addElement(template)
        break;
      case accionConst.edit:
        this.editElement(template)
        break;
      case accionConst.config:
        this.configElement(templateconfigUser);
        break;
      case accionConst.delete:
        this.deleteElement(this.productoSelected);
        break;

    }

  }
  addElement(template: TemplateRef<any>) {
    this.titleModal = "Nuevo Producto";
    this.openModalWithClass(template, "gray modal-md");
  }

  editElement(template: TemplateRef<any>) {
    this.titleModal = "Editar Producto";
    this.openModalWithClass(template, "gray modal-md");
  }
  configElement(template: TemplateRef<any>) {

  }

  deleteElement(data: ProductResponse) {
    this._productoService.delete(data.id_producto).subscribe(
      (data: number) => {
        if (data > 0) {

          this.getProducto();
        }
      },
      err => { }
    );

  }
  openModalWithClass(template: TemplateRef<any>, customclass: string) {
    this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config));
  }
 
  closeModal(res: boolean) {
    this.modalref.hide();
    if (res) {
      this.getProducto();
    }
  }

}

<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ title }}</h4>

  </div>
  <div class="modal-body">
    <form [formGroup]="myForm">
        
      <p-card>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for=""><strong>Tipo Documento</strong></label>
              <p-dropdown [options]="documentos" formControlName="tipo_documento" optionLabel="label" optionValue="value" [showClear]="true" placeholder="Selecciona  documento" class="w-100"></p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for=""><strong>Numero Documento</strong></label>
              <input  class="input-size" type="text" pInputText formControlName="numero_documento" placeholder="Ingrese Documento" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for=""><strong>Apellido Paterno</strong></label>
              <input class="input-size"  type="text" pInputText formControlName="apellido_paterno" placeholder="Ingrese Apellido Paterno" />
            </div>
          </div>
        </div>
      
        <div class="row">
        
          <div class="col-md-4">
            <div class="form-group">
              <label for=""><strong>Apellido Materno</strong></label>
              <input class="input-size" type="text" pInputText formControlName="apellido_materno" placeholder="Ingrese Apellido Materno" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label for=""><strong>Nombres</strong></label>
              <input  class="input-size" type="text" pInputText formControlName="nombres" placeholder="Ingrese Nombres" />
            </div>
          </div>
        </div>
        <div class="botones_input">
          <p-button  label="Guardar" (click)="ngSave()" [disabled]="disableButton || myForm.invalid" [raised]="true" severity="success"></p-button>
          <p-button label="Cancelar" (click)="closeModal(false)" [raised]="true" severity="danger"></p-button>
        </div>
      </p-card>
      
      
          
    </form>
  </div>
  
  

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { IngresoAlmacen_Request } from '../request/ingreso_almacen/ingreso_almacen.request';
import { CrudService } from './util/crud.service';
import { behaidorInterface } from './interfaces/behaidor-interface';
import { urlConstants } from '../constants/url-constants';
import { AlmacenProductoReporte_Response } from '../response/almacen-producto/almacen_producto_reporte.request';
import { AlmacenProductoReporte_Request } from '../request/almacen-producto/almacen_producto_reporte.request';

    
@Injectable({
	providedIn: 'root',
})
export class AlmacenProducto_Service
	extends CrudService<AlmacenProductoReporte_Request, AlmacenProductoReporte_Response>
	implements behaidorInterface<AlmacenProductoReporte_Response[]>
{
	private dataSource = new BehaviorSubject<AlmacenProductoReporte_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.almacen_producto);
	}

	setValueBehaidor(value: AlmacenProductoReporte_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
export enum AdminRoutes {
  Dashboard = 'dashboard',
  Events = 'events',
  Settings = 'settings',
  Elements = 'elements',
  Almacenes = 'almacen',
  Catalogo = 'catalogo',
  Procesos = 'proceso',
  Proveedor = 'proveedor'
}

export enum ElementRoutes {
  Alert = 'alert',
  Modal = 'modal',
  Buttons = 'buttons',
  Tabs = 'tabs',
  DataTable = 'data-table',
  Forms = 'forms',
  Almacen_Ingreso = 'reporte-almacen-ingreso',
  Almacen_Stock = 'reporte-almacen-stock',

  Ingreso_Almacen = "ingreso-almacen",
  
  Categorias = 'categoria',

  Productos  = 'productos',
  Reporte_venta = 'Registro_Venta',
  Editar_venta = 'Editar_Venta/:id',
  Imprimir_venta = 'Imprimir_Venta:id',
  Proceso_venta = 'proceso_venta',
  ReporteStock = 'reporte-stock-minimo'
}

export enum SettingRoutes {
  Profile = 'profile',
  Users = 'users',
}

import { Component, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { alert_warning } from '../functions/alert-function';
import { LoginService } from '../services/util/login.service';




@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {

  // constructor(
  //   private _LoginService: LoginService, private router: Router
  // ) {}

  constructor(private http: HttpClient, private router: Router) {}

  auth : any;
  canActivate() : any {
    
   // console.log('validate token');
    const url = 'https://api-sisgeven.cloud.elastika.pe/api/v1/user/auth';
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })
    };
    
    this.http.post( url, null, httpOptions ).subscribe({
      next: ( response : any ) => {
        if ( response.code == 200) {
          this.router.navigate([this.router.url]);
        }
      }, error: (err) => {
        this.router.navigate(['/login']);
        alert_warning('Sesión Caducada, Ingrese Login.');
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { Product } from 'src/app/request/stock-producto-reporte/stock';
import { AlmacenProductoReporte_Response } from 'src/app/response/almacen-producto/almacen_producto_reporte.request';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { ProductService } from 'src/app/services/prueba.service';
import { Venta_Service } from 'src/app/services/venta.service';
import { SplitterModule } from 'primeng/splitter';
import { CardModule } from 'primeng/card';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-reporte-stock-vista',
  standalone: true,
  imports: [TableModule,CommonModule,SplitterModule,CardModule,PaginatorModule,ButtonModule],
  templateUrl: './reporte-stock-vista.component.html',
  styleUrl: './reporte-stock-vista.component.css'
})
export class ReporteStockVistaComponent implements OnInit{
  products: Product[];
  eventsSubject: Subject<void> = new Subject<void>();
  AlmacenProducto_Response: AlmacenProductoReporte_Response[] = []
  filteredProducts: AlmacenProductoReporte_Response[] = [];
  itemsPerPage: number = 10; // Cambia el número según tus necesidades
  first: number = 0;
  searchTerm: string = '';
  get paginatedProducts(): AlmacenProductoReporte_Response[] {
    return this.AlmacenProducto_Response.slice(this.first, this.first + this.itemsPerPage);
  }

 constructor(private _AlmacenProducto_Service: AlmacenProducto_Service,) {}
  ngOnInit() {
      // this.productService.getProducts().then((data) => {
      //     this.products = data;
      // });

  this.GetAll_AlmacenProducto_Api();
  }

  GetAll_AlmacenProducto_Api() {
    const request_data = {
      almacen: localStorage.getItem('almacenes')
    };
    this._AlmacenProducto_Service.getByOther(request_data, "https://api-sisgeven.cloud.elastika.pe/api/v1/reporte-stock-almacen-alerta/").subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.AlmacenProducto_Response = response_data;
          this.filteredProducts = this.AlmacenProducto_Response.slice(); 
          this.filtrarProductos(); 
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        console.log("Error al llamar el servicio", error);
      }
    });
  }
   onPageChange(event: any) {
    this.first = event.first;
  }
  filtrarProductos() {
    this.filteredProducts = this.AlmacenProducto_Response.filter(product =>
      product.nombre.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    this.first = 0;
  }
  
  filtrarPorNombre(nombre: string) {
    this.filteredProducts = this.AlmacenProducto_Response.filter(product =>
      product.nombre.toLowerCase() === nombre.toLowerCase()
    );
   
    this.first = 0;
    
    
  }
  
  
}

import { Component, TemplateRef} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { ButtonAction } from 'src/app/common/button-action';
import { FilterForm } from 'src/app/common/filter-form';
import { FilterTable } from 'src/app/common/filter-table';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { tableCol } from 'src/app/common/table-cols';
import { accionConst } from 'src/app/constants/general-constats';
import { ProductResponse } from 'src/app/response/productos/producto.response';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { RegistroIngresoAlmacenComponent } from '../registro-ingreso-almacen/registro-ingreso-almacen.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { IngresoAlmacen_Service } from 'src/app/services/ingreso_almacen.service';
import { IngresoAlmacen_Response } from 'src/app/response/ingreso_almacen/ingreso_almacen.response';



@Component({
	selector: 'app-ingreso-almacen-reporte',
	standalone: true,
	imports: [FontAwesomeModule, TableModule, TablePaggingComponent, RegistroIngresoAlmacenComponent, CommonModule, ButtonModule, DialogModule, FormsModule, PaginatorModule, InputTextModule],
	templateUrl: './ingreso-almacen-reporte.component.html',
	styleUrl: './ingreso-almacen-reporte.component.css',
	providers: [
		BsModalService,
		SharedDataService
	],
})
export class IngresoAlmacenReporteComponent {

	faTableList = faTableList;
	config = bsConfigModal;
	modalref: BsModalRef = new BsModalRef();
	totalRecords: number = 0;
	loading: boolean = false;
	titleModal: string = "";

	buttonActions: ButtonAction[] = [
		{ accion: accionConst.add, data: {}, active: true },
		{ accion: accionConst.edit, data: {}, active: false },
		{ accion: accionConst.delete, data: {}, active: false },
		{ accion: accionConst.config, data: {}, active: false }
	];

	eventsSubject: Subject<void> = new Subject<void>();
	valuesTable: IngresoAlmacen_Response[] = [];
	cols: tableCol[] = [
		{ field: "id_ingreso", header: "ID" },
		{ field: "fecha", header: "Fecha" },
		{ field: "nombre_almacen", header: "Almacén" },
		{ field: "direccion", header: "dirección" },
		{ field: "detalles", header: "Detalles" },
		{ field: "estado", header: "Estado" }
	];

	IngresosAlmacen_Response: IngresoAlmacen_Response[] = []
	filterForm: FilterForm[] = [];
	IngresoAlmacen_Selected: IngresoAlmacen_Response = new IngresoAlmacen_Response();
	
	constructor(
		private _IngresoAlmacen_Service: IngresoAlmacen_Service,
		private modalService: BsModalService,
	
	) {
	}

	ngOnInit(): void {
		this.GetIngresosAlmacen_Api();
	}

	GetIngresosAlmacen_Api() {
		this.loading = false;
		this._IngresoAlmacen_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				this.loading = true;
				if (response_data) {
					response_data.forEach((ingreso: any) => {
						// Modificar el estado
						if (ingreso.estado === 2) {
							ingreso.estado = 'Activo';
							ingreso.style = { color: 'green', fontWeight: 'bold' }; // Establecer estilos directamente
						} else {
							ingreso.style = {}; // Establecer estilos por defecto para otros estados
						}
					});
	
					this.IngresosAlmacen_Response = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				this.loading = true;
				console.log("Error al llamar el servicio", error);
			}
		});
	}
	
	

	adjustProductData(products: ProductResponse[]): ProductResponse[] {
		// Ajusta los datos del estado según el valor
		return products.map(product => {
		  product.estado = product.estado === 2 ? "Activo" : "Inactivo";
		  //product.categoria_producto_id = product.categoria_producto_id === 1 ? 2 : 1;
	
		 
		  
		  
		  return product;
		});
	  }

	   findProductosArray(data: any): any[] | null {
  
    // Si la respuesta no es un array, busca en las propiedades
    for (const arr in data) {
      console.log("entrado a este for");
      if (Array.isArray(data[arr])) {
        // Si encuentra un array dentro de una propiedad, devuélvelo
        return data[arr];
      }
    }
    // Si no encuentra un array de productos, devuelve null
    return null;
  }
	changeFilter(name: string, array: FilterTable[]) {
		this.filterForm.map(x => {
			if (x.name == name) {
				x.data = array;
			}
		});
	}
	recibeAction(data: ButtonAction, template: TemplateRef<any>, templateconfigUser: TemplateRef<any>) {
		this.IngresoAlmacen_Selected = data.data;
		switch (data.accion) {
			case accionConst.add:
				this.IngresoAlmacen_Selected = new IngresoAlmacen_Response();
				this.addElement(template)
				break;
			case accionConst.edit:
				this.editElement(template)
				break;
			case accionConst.config:
				this.configElement(templateconfigUser);
				break;
			case accionConst.delete:
				// this.deleteElement(this.IngresoAlmacen_Selected);
				break;

		}

	}
	addElement(template: TemplateRef<any>) {
		this.titleModal = "Registro Producto Almacen";
		this.openModalWithClass(template, "modal-xl modal-custom");
	}

	editElement(template: TemplateRef<any>) {
		this.titleModal = "Editar Producto";
		this.openModalWithClass(template, "gray modal-md");
	}
	configElement(template: TemplateRef<any>) {

	}

	deleteElement(data: ProductResponse) {
		// this._productoService.delete(data.id_producto).subscribe(
		//   (data: number) => {
		//     if (data > 0) {

		//       this.getProducto();
		//     }
		//   },
		//   err => { }
		// );
	}
	openModalWithClass(template: TemplateRef<any>, customclass: string) {
		this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config),);
	}

	closeModal(res: boolean) {
		this.modalref.hide();
		if (res) {
			// this.getProducto();
		}
	}

}

import { Injectable } from '@angular/core';
import { CrudService } from './util/crud.service';

import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { urlConstants } from '../constants/url-constants';

import { behaidorInterface } from './interfaces/behaidor-interface';
import { ProductRequest } from '../request/producto/producto.request';
import { ProductResponse } from '../response/productos/producto.response';
import { MovimientoAlmacen_Request } from '../request/movimiento-almacen/movimiento-almacen-request';
import { MovimientoAlmacen_Response } from '../response/movimiento-almacen-response/movimiento-almacen-response';

@Injectable({
	providedIn: 'root',
})
export class Movimiento_Almacen_Service
	extends CrudService<MovimientoAlmacen_Request, MovimientoAlmacen_Response>
	implements behaidorInterface<MovimientoAlmacen_Response[]>
{
	private dataSource = new BehaviorSubject<MovimientoAlmacen_Response[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.movimiento_almacen);
	}

	setValueBehaidor(value: MovimientoAlmacen_Response[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}

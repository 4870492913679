import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ButtonAction } from 'src/app/common/button-action';
import { accionConst } from 'src/app/constants/general-constats';
import { Subject } from 'rxjs';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { Cliente_Response } from 'src/app/response/cliente/cliente-response';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { RegisterClienteComponent } from '../register-cliente/register-cliente.component';
import { TableModule } from 'primeng/table';
import { AgregarClienteVentasComponent } from '../agregar-cliente-ventas/agregar-cliente-ventas.component';
import { Cliente_Service } from 'src/app/services/cliente.service';
import { Almacen_Service } from 'src/app/services/almacen.service';
import { ProductoService } from 'src/app/services/producto.service';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Venta_Service } from 'src/app/services/venta.service';
import { alert_error, alert_success, alert_warning } from 'src/app/functions/alert-function';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-proceso-venta-register',
	standalone: true,
	imports: [CardModule, CommonModule, FormsModule, AgregarClienteVentasComponent, TableModule, RegisterClienteComponent, InputSwitchModule, TablePaggingComponent, InputNumberModule, DropdownModule, InputTextModule, CalendarModule, KeyFilterModule, InputTextareaModule],
	templateUrl: './proceso-venta-register.component.html',
	styleUrl: './proceso-venta-register.component.css',
	providers: [
		BsModalService,
		SharedDataService,
		Cliente_Service
	],
})
export class ProcesoVentaRegisterComponent implements OnInit {
	@Output() closeModalEmmit = new EventEmitter<boolean>();
	disableButton: boolean = false;
	value: string;

	isChecked: boolean = false;
	modo_pago: number = 1;
	metodo_pago: number = 1;
	countries: any[] | undefined;
	estado_comprobante: boolean = true;
	selectedCountry: string | undefined;
	incluirBoleta: string = 'NO';
	config = bsConfigModal;
	modalref: BsModalRef = new BsModalRef();
	totalRecords: number = 0;
	loading: boolean = false;
	date1: Date;
	titleModal: string = "";
	filteredClientes: any[];
	ventasId : number;

	buttonActions: ButtonAction[] = [
		{ accion: accionConst.add, data: {}, active: true },
		{ accion: accionConst.edit, data: {}, active: false },
		{ accion: accionConst.delete, data: {}, active: false },
		{ accion: accionConst.config, data: {}, active: false }
	];
	ClienteSelected: Cliente_Response = new Cliente_Response();
	eventsSubject: Subject<void> = new Subject<void>();
	filterForm: any;
	constructor(
	
		private _ClienteService: Cliente_Service,
		private _Almacen_Service: Almacen_Service,
		private _Producto_Service: ProductoService,
		private _AlmacenProducto_Service: AlmacenProducto_Service,
		private _Venta_Service: Venta_Service,
		private modalService: BsModalService,
		private router : Router,
		
	) {
		this.date1 = new Date();
	}
	ngOnInit(): void {
		this.comprobante.tipo_comprobante = 1;
		this.getClientes_Api();
		this.getAlmacenes_Api();
		this.getProductos_Api();
		this.getAlmacenes_Api_usuarios();
	}
	clientes: any[] = [];
	almacenes: any[] = [];
	almacenes_permisos: any[] = [];

	/* Enviar datos del TS AL HTML (DB) */
	productos_dinamico__db: any[][] = [];
	almacen_productos_dinamico__db: any[] = []; // array de pobjetos
	tipos_documento: any[] = [
		{
			id_tipo_documento: 1,
			nombre_tipo_documento: 'DNI',
		},
		{
			id_tipo_documento: 2,
			nombre_tipo_documento: 'PASAPORTE',
		},
		{
			id_tipo_documento: 3,
			nombre_tipo_documento: 'OTRO',
		}
	];

	tipos_comprobante: any[] = [
		{
			id_tipo_comprobante: 2,
			nombre_tipo_comprobante: 'BOLETA'
		},
		{
			id_tipo_comprobante: 3,
			nombre_tipo_comprobante: 'FACTURA'
		},
		{
			id_tipo_comprobante: 1,
			nombre_tipo_comprobante: 'PROFORMA '
		},
		{
			id_tipo_comprobante: 4,
			nombre_tipo_comprobante: 'NOTA DE CRÉDITO'
		},
		{
			id_tipo_comprobante: 5,
			nombre_tipo_comprobante: 'OTRO'
		},
	]

	/* Datos de la venta en el HTML */
	subtotal_venta = 0.00;
	descuento_total_venta = 0.00;
	igv_venta = 0.00;
	importe_total_venta = 0.00;
	venta_detalles = null;
	comprobante_detalles = null;
	total_venta__sin_descuento = 0.00; // Subtotal venta sin descuentos
	total_venta = 0.00;


	venta: any = {
		fecha: new Date
	};
	cliente: any = {};
	almacen: any = {};
	almacen_permiso: any = {};
	
	ventaDb: any = {};
	comprobante: any = {
		fecha: new Date
	};


	saveVentas():void{
		if(12){

		}
		else{
			this.register__venta();
	}
	}

	recibeAction(data: ButtonAction, template: TemplateRef<any>, templateconfigUser: TemplateRef<any>) {
		this.ClienteSelected = data.data;
		switch (data.accion) {
			case accionConst.add:
				this.ClienteSelected = new Cliente_Response();
				this.addElement(template)
				break;
			case accionConst.config:
				this.configElement(templateconfigUser);
				break;
		}
	}
	addElement(template: TemplateRef<any>) {

		this.titleModal = "Registra Cliente Nuevo";
		this.openModalWithClass(template, "modal-lg");
	}

	configElement(template: TemplateRef<any>) {
	}

	openModalWithClass(template: TemplateRef<any>, customclass: string) {
		this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config),);
	}

	closeModal(res: boolean) {
		this.modalref.hide();
		if (res) {
			// console.log("volver al registro venta");
		}
	}

	getClientes_Api(): void {
		this._ClienteService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;

				//console.log(response_data);
				if (response_data) {
					this.clientes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenes_Api(): void {
		const request_data = {
			usuario: localStorage.getItem('id_usuario'),
			
		}
		this._Almacen_Service.getByOther( request_data ).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.almacenes = response_data;

					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}


	getAlmacenes_Api_usuarios(): void {
		const request_data = {
			usuario: localStorage.getItem('id_usuario'),
			permiso_venta: 1
		}
		this._Almacen_Service.getByOther( request_data ).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.almacenes_permisos = response_data;

					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}


	getProductos_Api(): void {
		this._Producto_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos_dinamico__db = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductosByProductoIdAlmacenId_Api(producto: DropdownChangeEvent, i: number): void {
		const producto_id = producto.value;
		const almacen_id = this.almacen.id_almacen;
		const request_data = {
			producto: producto_id,
			almacen: almacen_id
		}
		this._AlmacenProducto_Service.getByOther(request_data).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					// this.categorias_dinamico = response_data;   
					this.almacen_productos_dinamico__db[i] = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductoById_Api(almacen_producto: DropdownChangeEvent, i: number): void {
		const producto_id = almacen_producto.value;
		this._AlmacenProducto_Service.getByid(producto_id).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos[i].precio = response_data.precio.toFixed(2);
					this.calularSubtatalProducto(i);
					this.calcularPreciosVenta();
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	/* Function - Calculate precio_venta of product */
	calularSubtatalProducto (i : number) : void {
		console.log('calculando');
		this.productos[i].precio_venta = ((this.productos[i].cantidad * this.productos[i].precio) - this.productos[i].descuento).toFixed(2);
		this.productos[i].subtotal = ((this.productos[i].cantidad * this.productos[i].precio)).toFixed(2);
		this.calcularPreciosVenta()
	}

	/* Function - Calculate prices of sales */
	calcularPreciosVenta () : void {
		/* Calcular venta total */
		const subtotal_venta = this.productos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.precio_venta), 0);
		const total_venta__sin_descuento = this.productos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.subtotal), 0);
		const descuento_total_venta = this.productos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.descuento), 0);
		this.descuento_total_venta = descuento_total_venta;
		this.importe_total_venta = subtotal_venta;
		this.igv_venta = (subtotal_venta / 1.18) * 0.18;
		this.subtotal_venta = subtotal_venta;
		this.total_venta__sin_descuento = total_venta__sin_descuento;
		this.total_venta = subtotal_venta - this.igv_venta;
		this.comprobante.total_venta = subtotal_venta;
	}
	getVentas_ById(id: number): void {
		const id_venta = id;
		this._Venta_Service.getByid(id_venta).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.ventaDb = response_data;
				} else {
					console.error('No se encontraron datos.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio. => ", error);
			}
		});
	}

	getClienteById_Api(cliente: DropdownChangeEvent): void {
		const id_cliente = cliente.value;
		this._ClienteService.getByid(id_cliente).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.cliente = response_data;
					// Inicializa filteredClientes con todas las opciones disponibles
					this.filteredClientes = this.clientes;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 100);
				} else {
					console.error('No se encontraron datos.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio. => ", error);
			}
		});
	}

	/* Register venta */
	register__venta(): void {
		const comprobante__r = {
			codigo: null,
			monto_total: this.importe_total_venta,
			tipo_comprobante: this.comprobante.tipo_comprobante,
			serie_comprobante: this.comprobante.serie,
			numero_comprobante: this.comprobante.numero,
			fecha_comprobante: this.comprobante.fecha,
			detalles: this.comprobante_detalles
		};

		const modo_pago = (this.isChecked) ? 2 : 1;
		const metodo_pago = (this.isChecked) ? 2 : 1;

		const request_data = {
			cliente_id: this.cliente.id_cliente,
			id_almacen: this.almacen.id_almacen,
			codigo: null,
			monto: this.total_venta__sin_descuento,
			descuento: this.descuento_total_venta,
			descuento_porcentaje: null,
			igv: this.igv_venta,
			fecha_venta : this.venta.fecha_venta,
			monto_total: this.importe_total_venta,
			detalles: this.venta_detalles,
			comprobante: (this.estado_comprobante) ? comprobante__r : null,
			productos: this.productos,
			modo_pago: modo_pago,
			metodo_pago: metodo_pago
		};

		// Llama al servicio para crear la venta
		this._Venta_Service.create(JSON.parse(JSON.stringify(request_data))).subscribe({

			next: (response: any) => {

				const data_error = response.code;
				const venta_id = (response.data) ? response.data.id_venta : null;

				console.log(data_error);

				if (data_error == 409) {
					alert_warning("No se ha registrado ningún producto. Stock Agotado");
				} else {
					alert_success("Registrado correctamente");
					this.closeModalEmmit.emit(true);
					this.disableButton = false;
					this.closeModal(true);

					if (venta_id) {
						const url = `https://api-sisgeven.cloud.elastika.pe/api/v1/comprobante-venta-pdf/venta/${venta_id}`;
						console.log(url);
						window.open(url, '_blank')?.focus();
					} else {
						alert('No se pudo obtener el ID de venta.');
					}

					this.router.navigate(['/admin/proceso/proceso_venta']);
				}
			},


			error: (err) => {
				if (err.status === 409) {
					alert_error("ERROR", "Debe rellenar todos los campos");
				} else {
					//console.error("Error al registrar la venta:", err);
					alert_error("ERROR", "ERROR en registrar la venta No hay stock del Producto");
				}
				this.disableButton = false;
			}
		});
	}


	filterClientes(event: any) {
		const query = event.query?.toLowerCase();

		if (query) {
			this.clientes = this.clientes.filter(cliente => {
				const nombreCompleto = cliente.nombre_completo;

				if (nombreCompleto && typeof nombreCompleto === 'string') {
					return nombreCompleto.toLowerCase().includes(query);
				}
				return false;
			});
		} else {
			// Restaura la lista completa cuando no hay filtro
			this.getClientes_Api();
		}
	}

	/* -------------------------------------------- Add and delete row of prodcut table -------------------------------------------- */
		productos: any[] = [];
		agregarFila() {
			const nuevaFila = { cantidad: 1, precio: 0.00, descuento: 0.00, precio_venta: 0.00, almacen_id: this.almacen.id_almacen };
			this.productos.push(nuevaFila);
		}
		eliminarFila(i: number) {
			this.productos.splice(i, 1);
			this.calcularPreciosVenta();
		}
		Check_Function(event: any) {
			if (event.checked) {
				this.modo_pago = 2;
				this.metodo_pago = 2;
			} else {
				this.modo_pago = 1;
				this.metodo_pago = 1;
			}
			// console.log("modo_pago:", this.modo_pago);
			// console.log("metodo_pago:", this.metodo_pago);
		}
	/* ------------------------------------------------------------------------------------------------------------------------------ */
		

}


export class CategoriaResponse {
    id_categoria_producto: number;
    codigo: string;
    nombre: string;
    nombre_corto: string;
    nombre_largo: string;
    estado: number;
    detalles: string;
    
    constructor(){
        this.id_categoria_producto =  0,
        this.codigo = "",
        this.nombre = "",
        this.nombre_corto = "",
        this.nombre_largo = "",
        this.estado = 0,
        this.detalles = ""
    }
}
import { Component, ElementRef,  Renderer2, TemplateRef } from '@angular/core';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ButtonAction } from 'src/app/common/button-action';
import { FilterForm } from 'src/app/common/filter-form';
import { FilterTable } from 'src/app/common/filter-table';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { tableCol } from 'src/app/common/table-cols';
import { accionConst } from 'src/app/constants/general-constats';
import { Ventas_Response } from 'src/app/response/ventas/ventas-response';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { Venta_Service } from 'src/app/services/venta.service';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProcesoVentaRegisterComponent } from '../proceso-venta-register/proceso-venta-register.component';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { EditVentaComponent } from '../editar-venta/edit-venta.component';
import { CommonModule, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { alert_warning } from 'src/app/functions/alert-function';
import { DropdownModule } from 'primeng/dropdown';
import { Almacen_Service } from 'src/app/services/almacen.service';

interface Almacen {
	id: string;
	name: string;
  }
  
@Component({
	selector: 'app-reporte-ventas',
	standalone: true,
	imports: [TablePaggingComponent, CommonModule, DropdownModule, FormsModule, CommonModule, CalendarModule, EditVentaComponent, ProcesoVentaRegisterComponent, ButtonModule, FontAwesomeModule, ProcesoVentaRegisterComponent],
	templateUrl: './reporte-ventas.component.html',
	styleUrl: './reporte-ventas.component.css',
	providers: [
		BsModalService,
		SharedDataService,
		Venta_Service,
		DatePipe
	],
})
export class ReporteVentasComponent {

	/* ---------------------------------------- Modal ---------------------------------------- */
	rangeDates: Date[] ;


	faTableList = faTableList;
	config = bsConfigModal;
	modalref: BsModalRef = new BsModalRef();
	totalRecords: number = 0;
	loading: boolean = false;
	titleModal: string = "";

	almacenes : Almacen[] | undefined;
	selectedAlmacen: Almacen ;
	almacenes_reporte_venta: any[] = [];
	registroEditando: Ventas_Response | null;
	editFormVisible = false;
	cliente: any = {};
	ventaDb: any = {};
	datosEnviados: any;
	almacen_reporte_fecha: any = {};
	/* -------------------------------------------------------------------------------------- */

	/* ---------------------------------- Data Table View ---------------------------------- */
	buttonActions: ButtonAction[] = [
		{ accion: accionConst.add, data: {}, active: false },
		{ accion: accionConst.edit, data: {}, active: true },
		{ accion: accionConst.delete, data: {}, active: false },
		{ accion: accionConst.config, data: {}, active: true }
	];


	eventsSubject: Subject<void> = new Subject<void>();
	cols: tableCol[] = [
		{ header: "ID", field: "id_venta" },
		{ header: "Fecha Venta", field: "fecha_venta" },
		{ header: "Fecha Registro", field: "fecha_registro" },
		{ header: "Monto total", field: "monto_total" },
		{ header: "Tienda", field: "almacen" },
		{ header: "Cliente", field: "nombres" },
		{ header: "Estado", field: "estado" },
		{ header: "Tipo comprobante", field: "tipo_comprobante" },
		{ header: "Comprobante", field: "comprobante" },
	];

	Venta_Response: Ventas_Response[] = []
	filterForm: FilterForm[] = [];
	Venta_Selected: Ventas_Response = new Ventas_Response;
	selectedVentaData: any;
	ventaData: any;
	
	/* -------------------------------------------------------------------------------------------- */

	/* --------------------------- Constructor - Setear vars as service --------------------------- */
	constructor(
		private router: Router,
		private modalService: BsModalService,
		private sharedService: SharedDataService,
		private route: ActivatedRoute,
		private _Venta_Service: Venta_Service,
		private datePipe: DatePipe,
		private _Almacen_Service: Almacen_Service,
	) {
		this.rangeDates = [];

	}
	/* -------------------------------------------------------------------------------------------- */

	/* ------------------------------------- Events starup app ------------------------------------ */
	ngOnInit(): void {

		const idVenta = this.route.snapshot.params['id'];
		this.Get_ReporteVentas_Api();
		this.getAlmacenes_Api();
	}


	
	/* -------------------------------------------------------------------------------------------- */

	/* Get and view data list of Ventas */
	Get_ReporteVentas_Api() {
		this.loading = false;
		const request_data = {
			almacen: localStorage.getItem('almacenes')
		};
		this._Venta_Service.getByOther(request_data, 'https://api-sisgeven.cloud.elastika.pe/api/v1/reporte-ventas/').subscribe({
			next: (response: any) => {
				const response_data = response.data;	
				
				response_data.forEach((venta: any) => {
					venta.monto_total = Number(venta.monto_total).toFixed(1);
					// Modificar el tipo de comprobante
					switch (venta.tipo_comprobante) {
						case 2:
							venta.tipo_comprobante = 'Boleta';
							break;
						case 3:
							venta.tipo_comprobante = 'Factura';
							break;
						case 1:
							venta.tipo_comprobante = 'Proforma';
							break;
						case 4:
							venta.tipo_comprobante = 'Nota de Credito';
							break;
						default:
							venta.tipo_comprobante = 'Otro';
					}
					// Modificar el estado
					if (venta.estado === 1) {
						venta.estado = 'Activo';
					} else {
						venta.estado = 'Inactivo';
					}
				});

				this.loading = true;
				this.Venta_Response = response_data;
				setTimeout(() => {
					this.eventsSubject.next();
				}, 200);
			}, error: (error) => {
				this.loading = true;
				console.log("Error al llamar el servicio", error);
			}
		});
	}



	getAlmacenes_Api(): void {
		const request_data = {
		  usuario: localStorage.getItem('id_usuario')
		};
		this._Almacen_Service.getByOther(request_data).subscribe({
		  next: (response: any) => {
			const response_data = response.data;
			console.log(response_data);
			if (response_data) {
			  this.almacenes_reporte_venta = response_data;
	
			  // Accediendo al campo id_almacen
			  const idAlmacen = response_data.id_almacen;
			  console.log('ID del almacén:', idAlmacen);
	
			  setTimeout(() => {
				this.eventsSubject.next();
			  }, 200);
			} else {
			  console.error('No se encontró un array de productos en la respuesta.');
			}
		  },
		  error: (error) => {
			console.log("Error al llamar el servicio", error);
		  }
		});
	  }
 




	getVentas_ById(id: number): void {
		const id_venta = id;
		console.log("ID de venta:", id_venta);
		this._Venta_Service.getByid(id_venta).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.ventaDb = response_data;
					console.log(response_data);

					// Resto del código...
				} else {
					console.error('No se encontraron datos.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio. => ", error);
			}
		});
	}



	changeFilter(name: string, array: FilterTable[]) {
		this.filterForm.map(x => {
			if (x.name == name) {
				x.data = array;
			}
		});
	}

	recibeAction(data: ButtonAction, template: TemplateRef<any>, templateconfigUser: TemplateRef<any>, dataVenta: any) {
		this.Venta_Selected = data.data;
		switch (data.accion) {
			case accionConst.edit:
				// Pasa los datos de la venta seleccionada a la función editElement
				this.editElement(template, this.Venta_Selected);
				//this.cliente = this.Venta_Selected;
				break;
			case accionConst.config:
				this.imprimirBoletaPDF(template, this.Venta_Selected);
				break;


		}
	}


	enviarDatos() {
		const datos = { /* ... tus datos aquí ... */ };
		this.sharedService.setVentaData(datos);
	}


	imprimirReporteVentaPDF(template: TemplateRef<any>, ventaData: any) {
		if (this.rangeDates.length === 2 || this.almacen_reporte_fecha.id_almacen) {
		  const fechaInicial = this.datePipe.transform(this.rangeDates[0], 'dd-MM-yyyy');
		  const fechaFinal = this.datePipe.transform(this.rangeDates[1], 'dd-MM-yyyy');
		  const almacenid = this.almacen_reporte_fecha.id_almacen;
		  
		  // Construye la URL con las fechas
		  const url = `https://api-sisgeven.cloud.elastika.pe/api/v1/reporte-venta-almacen-pdf/almacen/${almacenid}/fecha/${fechaInicial}/${fechaFinal}`;
		  
		  // Abre la URL en una nueva ventana
		  window.open(url, '_blank');
		} else {
		  alert_warning("Selecione-Fecha Correcta")
		}
	}

	imprimirBoletaPDF(template: TemplateRef<any>, ventaData: any) {
		
		console.log("click a imprimir");


		const url = `https://api-sisgeven.cloud.elastika.pe/api/v1/comprobante-venta-pdf/venta/${ventaData.id_venta}`;
		window.open(url, '_blank');
	}

	editElement(template: TemplateRef<any>, ventaData: any) {
		this.titleModal = "Editar Venta";
		this.getVentas_ById(ventaData.id_venta);
		this.selectedVentaData = ventaData;
		this.router.navigate([`/admin/proceso/Editar_Venta/`, ventaData.id_venta]);

		console.log('URL generada:', this.router.url);
	}




	deleteElement(data: Ventas_Response) {

	}
	openModalWithClass(template: TemplateRef<any>, customclass: string, data: any) {
		this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config),);
	}

	closeModal(res: boolean) {
		this.modalref.hide();
		if (res) {
			// this.getProducto();
		}
	}
	navigateToRegistroIngreso() {
		this.router.navigate(['/admin/proceso/Registro_Venta']);
	}
	navigateStockPage() {
		this.router.navigate(['/admin/proceso/reporte-stock-minimo']);
	}
}

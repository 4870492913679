<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ title }}</h4>
</div>
<p-card>
    <div class="header-boton">
        <div class="botton-1">
            <p-button icon="pi pi-plus" (click)="agregarFila()" label="Agregar Productos"></p-button>
        </div>
        <div class="select-1">
            <p-dropdown [options]="almacenes_origen" optionValue="id_almacen" [(ngModel)]="almacen_origen.id_almacen"
                optionLabel="nombre" [showClear]="true" placeholder="Almacén Origen"></p-dropdown>
        </div>
        <div class="select-1">
            <p-dropdown [options]="almacenes_destino" optionValue="id_almacen" [(ngModel)]="almacen_destino.id_almacen"
                optionLabel="nombre" [showClear]="true" placeholder="Almacén Destino"></p-dropdown>
        </div>
        <div class="select-2">
            <label for="inputDetalle">Detalles</label>
            <input id="inputDetalle" type="text" pInputText/>
        </div>
    </div>
</p-card>
<p-divider type="solid"></p-divider>
<p-card>
    <table>
        <thead>
            <tr>
                <th width="10%">Producto</th>
                <th width="20%">Talla</th>
                <th width="10%">Unidad Medida</th>
                <th width="5%">Cantidad</th>
               
                <th width="10%">Detalles</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let producto of productos; let i = index">
                <td>
                    <p-dropdown [options]="productos_dinamico__db" class="drop-size" optionValue="id_producto"
                        optionLabel="nombre_largo" [showClear]="true" placeholder="Producto"
                        [(ngModel)]="producto.id_producto"
                        (onChange)="getAlmacenProductosByProductoIdAlmacenId_Api($event, i)"></p-dropdown>
                </td>
                <td>
                    <p-dropdown [options]="almacen_productos_dinamico__db[i]" class="drop-size"
                        optionValue="id_almacen_producto" id="id_almacen_producto" optionLabel="nombre_corto" [showClear]="true"
                        placeholder="Talla" [(ngModel)]="producto.id_almacen_producto" (onChange)="getAlmacenProductoById_Api($event, i)"></p-dropdown>
                </td>
                <td>
                    <p-dropdown [options]="unidad_medida" [(ngModel)]="producto.unidad_medida" optionLabel="name"
                        [showClear]="true" optionValue="code" placeholder="UNIDAD" [readonly]="true"></p-dropdown>
                </td>
                <td><input class="input-size" type="text" pInputText [(ngModel)]="producto.cantidad" /></td>
             
                <td><input type="text" pInputText [(ngModel)]="producto.detalles" /></td>
                <td>
                    <p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash"
                        (click)="eliminarFila(i)" severity="danger"></p-button>
                </td>
            </tr>
        </tbody>
    </table>
</p-card>
<p-divider type="solid"></p-divider>
<p-card >
    <div class="register-botton">
        <button pButton type="button" class="p-button-success mb-5" icon="pi pi-save" label="Registrar"
            (click)="registerProductoAlmacen_Api()"></button>
        <p-button label="Cancelar" (click)="closeModal(true)" [raised]="true" severity="danger"></p-button>
    </div>
</p-card>
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, Renderer2, TemplateRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { Ventas_Response } from 'src/app/response/ventas/ventas-response';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { Cliente_Service } from 'src/app/services/cliente.service';
import { Subject } from 'rxjs';
import { Almacen_Service } from 'src/app/services/almacen.service';
import { ProductoService } from 'src/app/services/producto.service';
import { AlmacenProducto_Service } from 'src/app/services/almacen_producto.service';
import { Venta_Service } from 'src/app/services/venta.service';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { Cliente_Response } from 'src/app/response/cliente/cliente-response';
import { alert_error, alert_success, alert_warning } from 'src/app/functions/alert-function';
import { ReporteVentasComponent } from '../reporte-ventas/reporte-ventas.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-edit-venta',
	standalone: true,
	imports: [ButtonModule, CommonModule, CardModule, FormsModule, InputSwitchModule, TablePaggingComponent, InputNumberModule, DropdownModule, InputTextModule, CalendarModule, KeyFilterModule, InputTextareaModule],
	templateUrl: './edit-venta.component.html',
	styleUrl: './edit-venta.component.css',
	providers: [
		BsModalService,
		SharedDataService,
		Cliente_Service,
	],
})
export class EditVentaComponent {
	formattedDate: Date;

	empleado_id: number;
	id_cliente: number;
	codigo: string;
	monto: number;
	//////////////////////////////////////////////

	filteredClientes: any[];
	////
	isChecked: boolean = false;
	modo_pago: number = 1;
	metodo_pago: number = 1;
	/////////////////////////////////////////////
	registroEditando: Ventas_Response | undefined;

	@Output() closeModalEmmit = new EventEmitter<boolean>();
	disableButton: boolean = false;
	value: string;
	countries: any[] | undefined;
	checked: boolean = false;
	selectedCountry: string | undefined;
	incluirBoleta: string = 'NO';
	config = bsConfigModal;
	modalref: BsModalRef = new BsModalRef();
	totalRecords: number = 0;
	loading: boolean = false;
	date1: Date;
	titleModal: string = "";
	///////////
	idVenta: number;
	ClienteSelected: Cliente_Response = new Cliente_Response();
	eventsSubject: Subject<void> = new Subject<void>();
	filterForm: any;


	estado_comprobante: boolean = true;

	selectedVentaData: any = null;
	cliente: any = {};
	constructor(

		private _ClienteService: Cliente_Service,
		private _Almacen_Service: Almacen_Service,
		private _Producto_Service: ProductoService,
		private _AlmacenProducto_Service: AlmacenProducto_Service,
		private _Venta_Service: Venta_Service,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private router: Router,

	) { }
	private reporte_ventas: ReporteVentasComponent


	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			const idParam = params.get('id');
			if (idParam !== null) {
				this.idVenta = +idParam;
				this.getVentas_ById(this.idVenta);
			} else {
				console.error('ID no encontrado en la URL.');
			}
		});

		this.getClientes_Api();
		this.getAlmacenes_Api();
		this.getProductos_Api();

		// Lógica para prellenar el formulario con los datos de selectedVentaData

	}


	clientes: any[] = [];
	almacenes: any[] = [];
	ventaData: any;
	/* Enviar datos del TS AL HTML (DB) */
	productos_dinamico__db: any[][] = [];
	almacen_productos_dinamico__db: any[] = []; // array de objetos
	almacen_productos_dinamico__new__db: any[] = []; // array de objetos
	tipos_documento: any[] = [
		{
			id_tipo_documento: 1,
			nombre_tipo_documento: 'DNI',
		},
		{
			id_tipo_documento: 2,
			nombre_tipo_documento: 'PASAPORTE',
		},
		{
			id_tipo_documento: 3,
			nombre_tipo_documento: 'OTRO',
		}
	];

	tipos_comprobante: any[] = [
		{
			id_tipo_comprobante: 2,
			nombre_tipo_comprobante: 'BOLETA'
		},
		{
			id_tipo_comprobante: 3,
			nombre_tipo_comprobante: 'FACTURA'
		},
		{
			id_tipo_comprobante: 1,
			nombre_tipo_comprobante: 'PROFORMA '
		},
		{
			id_tipo_comprobante: 4,
			nombre_tipo_comprobante: 'NOTA DE CRÉDITO'
		},
		{
			id_tipo_comprobante: 5,
			nombre_tipo_comprobante: 'OTRO'
		},
	]

	/* Datos de la venta en el HTML */
	subtotal_venta = 0.00;
	descuento_total_venta = 0.00;
	igv_venta = 0.00;
	importe_total_venta = 0.00;
	venta_detalles = null;
	comprobante_detalles = null;
	total_venta__sin_descuento = 0.00; // Subtotal venta sin descuentos
	total_venta = 0.00;


	venta: any = {
		fecha: new Date
	};
	ventaDb: any = {

	};
	productos_db: any = {};
	almacen: any = {};
	comprobante: any = {
		fecha: new Date
	};

	getVentas_ById(id: number): void {
		const id_venta = id;
		const request = {
			venta: id_venta
		};
		this._Venta_Service.getByOther(request, 'https://api-sisgeven.cloud.elastika.pe/api/v1/venta-completa/').subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.ventaDb = response_data;
					const productos = this.ventaDb.productos;
					this.almacen.id_almacen = this.ventaDb.almacen_id;
					this.estado_comprobante = (this.ventaDb.id_comprobante) ? true : false;
					productos.forEach((producto: any, i: number) => {
						const nuevaFila = {
							cantidad: producto.cantidad,
							id_detalle_venta: producto.id_detalle_venta,
							id_producto: producto.producto_id,
							id_almacen_producto: producto.id_almacen_producto,
							id_almacen: this.almacen.id_almacen,
							precio: producto.precio,
							descuento: producto.descuento,
							precio_venta: producto.precio_venta,
							subtotal: producto.precio_venta,
							detalles: producto.detalles,
							subtotal_sin_descuento: producto.cantidad * producto.precio
						};
						this.getAlmacenProductosByProductoIdAlmacenId_Dinamico_Api(producto.producto_id, i)
						this.calcularPreciosVenta();
						this.productos.push(nuevaFila);
						this.calularSubtotalProducto(i);
					});
					// Configurar el estado del switch basado en metodo_pago
					this.isChecked = (this.ventaDb.metodo_pago === 2) ? true : false;

					if (this.ventaDb) {
						this.checked = true;
					}
				} else {
					console.error('No se encontraron datos.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio. => ", error);
			}
		});
	}

	accederAVentaData() {
		const ventaData = this.reporte_ventas.ventaData;
		// Usa ventaData según sea necesario
	}
	cerrarModal(): void {
		this.modalref.hide();
	}

	getClientes_Api(): void {
		this._ClienteService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.clientes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenes_Api(): void {
		this._Almacen_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.almacenes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getProductos_Api(): void {
		this._Producto_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos_dinamico__db = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductosByProductoIdAlmacenId_Api(producto: DropdownChangeEvent, i: number): void {
		const producto_id = producto.value;
		// const almacen_id = this.almacen.id_almacen;
		const id_almacen = this.almacen.id_almacen;
		const request_data = {
			producto: producto_id,
			almacen: id_almacen
		}
		this._AlmacenProducto_Service.getByOther(request_data).subscribe({
			next: (response: any) => {
				const response_data = response.data;

				if (response_data) {
					// this.categorias_dinamico = response_data;   
					this.almacen_productos_dinamico__db[i] = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}
	getAlmacenProductosByProductoIdAlmacenId_New_Api(producto: DropdownChangeEvent, i: number): void {
		const producto_id = producto.value;
		const id_almacen = this.almacen.id_almacen;
		const request_data = {
			producto: producto_id,
			almacen: id_almacen
		}
		this._AlmacenProducto_Service.getByOther(request_data).subscribe({
			next: (response: any) => {
				const response_data = response.data;

				if (response_data) {
					this.almacen_productos_dinamico__new__db[i] = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	/* Get ALmacenProducto dinamico - table */
	getAlmacenProductosByProductoIdAlmacenId_Dinamico_Api(producto: number, i: number): void {
		const producto_id = producto;
		// const almacen_id = this.almacen.id_almacen;
		const almacen_id = this.almacen.id_almacen;
		const request_data = {
			producto: producto_id,
			almacen: almacen_id
		}
		this._AlmacenProducto_Service.getByOther(request_data).subscribe({
			next: (response: any) => {
				const response_data = response.data;

				if (response_data) {
					// this.categorias_dinamico = response_data;   
					this.almacen_productos_dinamico__db[i] = response_data
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductoById_Api(almacen_producto: DropdownChangeEvent, i: number): void {
		const producto_id = almacen_producto.value;
		this._AlmacenProducto_Service.getByid(producto_id).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos[i].precio = response_data.precio.toFixed(2);
					this.calularSubtotalProducto(i);
					this.calcularPreciosVenta();
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	getAlmacenProductoById_New_Api(almacen_producto: DropdownChangeEvent, i: number): void {
		const producto_id = almacen_producto.value;
		this._AlmacenProducto_Service.getByid(producto_id).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.productos_new[i].precio = response_data.precio.toFixed(2);
					this.calularSubtotalProducto_New(i);
					this.calcularPreciosVenta();
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontró un array de productos en la respuesta.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio", error);
			}
		});
	}

	/* Function - Calculate precio_venta of product */
	calularSubtotalProducto(i: number): void {
		this.productos[i].precio_venta = ((this.productos[i].cantidad * this.productos[i].precio) - this.productos[i].descuento).toFixed(2);
		this.productos[i].subtotal_sin_descuento = ((this.productos[i].cantidad * this.productos[i].precio)).toFixed(2);
		this.calcularPreciosVenta()
	}
	calularSubtotalProducto_New(i: number): void {
		this.productos_new[i].precio_venta = ((this.productos_new[i].cantidad * this.productos_new[i].precio) - this.productos_new[i].descuento).toFixed(2);
		this.productos_new[i].subtotal = ((this.productos_new[i].cantidad * this.productos_new[i].precio)).toFixed(2);
		this.productos_new[i].subtotal_sin_descuento = ((this.productos_new[i].cantidad * this.productos_new[i].precio)).toFixed(2);
		this.calcularPreciosVenta()
	}

	/* Function - Calculate prices of sales */
	calcularPreciosVenta(): void {
		/* Calcular venta total */
		/* VENTA - DB */
		const subtotal_venta = this.productos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.precio_venta), 0);

		const total_venta__sin_descuento = this.productos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.subtotal_sin_descuento), 0);
		const descuento_total_venta = this.productos.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.descuento), 0);
		/* VENTA - NEW */
		const subtotal_venta_new = this.productos_new.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.precio_venta), 0);
		const total_venta__sin_descuento_new = this.productos_new.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.subtotal), 0);
		const descuento_total_venta_new = this.productos_new.reduce((previousValue, currentValue) => previousValue + parseFloat(currentValue.descuento), 0);
		/* CALCULAR AMBOS */
		this.ventaDb.descuento = descuento_total_venta + descuento_total_venta_new;
		this.ventaDb.monto_total = subtotal_venta + subtotal_venta_new;
		this.ventaDb.igv = (subtotal_venta / 1.18) * 0.18;
		this.subtotal_venta = subtotal_venta - subtotal_venta_new;
		this.total_venta__sin_descuento = total_venta__sin_descuento + total_venta__sin_descuento_new;
		this.total_venta = subtotal_venta - this.igv_venta;
		this.comprobante.total_venta = subtotal_venta;
		//console.log(this.ventaDb);

		//console.log(subtotal_venta_new);
		//console.log(subtotal_venta);
		//console.log(this.productos);


	}

	getClienteById_Api(cliente: DropdownChangeEvent): void {
		const id_cliente = cliente.value;
		this._ClienteService.getByid(id_cliente).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.cliente = response_data;
					// Inicializa filteredClientes con todas las opciones disponibles
					this.filteredClientes = this.clientes;

					setTimeout(() => {
						this.eventsSubject.next();
					}, 100);
				} else {
					console.error('No se encontraron datos.');
				}
			},
			error: (error) => {
				console.log("Error al llamar el servicio. => ", error);
			}
		});
	}

	filterClientes(event: any) {
		const query = event.query?.toLowerCase();

		if (query) {
			this.clientes = this.clientes.filter(cliente => {
				const nombreCompleto = cliente.nombre_completo;

				if (nombreCompleto && typeof nombreCompleto === 'string') {
					return nombreCompleto.toLowerCase().includes(query);
				}

				return false;
			});
		} else {
			// Restaura la lista completa cuando no hay filtro
			this.getClientes_Api();
		}
	}

	/* Update venta */

	update__venta(): void {
		const productos_db = this.productos;
		const productos_new = this.productos_new;
		const productos_delete = this.productos_delete;
	
		productos_db.forEach((objeto: any) => {
			delete objeto.id_unidad_producto;
			delete objeto.nombre;
			delete objeto.nombre_corto;
			delete objeto.nombre_largo;
			delete objeto.caracteristicas;
		});
	
		const comprobante__r =  {
			id_comprobante: this.ventaDb.id_comprobante,
			codigo: null,
			monto_total: this.ventaDb.monto_total_comprobante,
			tipo_comprobante: this.ventaDb.tipo_comprobante,
			serie_comprobante: this.ventaDb.serie_comprobante,
			numero_comprobante: this.ventaDb.numero_comprobante,
			fecha: this.ventaDb.fecha_comprobante,
			detalles: this.ventaDb.detalles_comprobante
		}
	
		const request = {
			cliente_id: this.ventaDb.id_cliente,
			codigo: null,
			monto: this.ventaDb.monto,
			descuento: this.ventaDb.descuento,
			descuento_porcentaje: null,
			igv: this.ventaDb.igv,
			metodo_pago: this.isChecked ? 2 : 1, 
			modo_pago: this.isChecked ? 2 : 1, 
			monto_total: this.ventaDb.monto_total,
			detalles: this.ventaDb.detalles,
			fecha_venta : this.ventaDb.fecha_venta,
			comprobante: (this.estado_comprobante) ? comprobante__r : null,
			productos_db: productos_db,
			productos_new: productos_new,
			productos_delete: productos_delete
		}
	
		console.log(request);
	
		this._Venta_Service.update(this.ventaDb.id_venta, JSON.parse(JSON.stringify(request))).subscribe({
			next: (response: any) => {
				this.isChecked = (response.data && response.data.metodo_pago === 2 && response.data.modo_pago === 2) ? true : false;
				const data_error = response.code;
				console.log(data_error);
				if (data_error == 409) {
					alert_warning("Error producto no hay stock");
				} else {
					alert_success("Registrado de forma correcta");
					this.closeModalEmmit.emit(true);
					this.disableButton = false;
					this.closeModal(true);
					this.router.navigate(['/admin/proceso/proceso_venta']);
				}
			},
			error: (err) => {
				if (err.status === 409) {
					alert_error("ERROR", "Registra los campos del producto");
				} else {
					alert_error("ERROR", "Registra los campos del producto");
				}
				this.disableButton = false;
			}
		});
	}

	/* -------------------------------------------- Add and delete row of prodcut table -------------------------------------------- */
	productos: any[] = [];
	productos_delete: any[] = [];

	productos_new: any[] = [];
	agregarFila() {
		const nuevaFila = { cantidad: 1, precio: 0.00, descuento: 0.00, precio_venta: 0.00, id_almacen: this.almacen.id_almacen };
		this.productos_new.push(nuevaFila);
	}
	eliminarFila(i: number) {
		this.productos_delete.push(
			{
				id_detalle_venta: this.productos[i].id_detalle_venta,
			}
		);

		this.productos.splice(i, 1);
		this.calcularPreciosVenta();
		this.ClienteSelected;

	}
	eliminarFila_New(i: number) {
		this.productos_new.splice(i, 1);
		this.calcularPreciosVenta();
		this.ClienteSelected
	}
	/* --------------------------------------*/

	openModalWithClass(template: TemplateRef<any>, customclass: string) {
		this.modalref = this.modalService.show(template, Object.assign({}, { class: customclass }, this.config),);
	}

	closeModal(res: boolean) {
		this.modalref.hide();
		if (res) {
			// console.log("volver al registro venta");
		}
	}
	Check_Function(event: any) {
		if (event.checked) {
			this.modo_pago = 2;
			this.metodo_pago = 2;
		} else {
			this.modo_pago = 1;
			this.metodo_pago = 1;
		}
		// console.log("modo_pago:", this.modo_pago);
		// console.log("metodo_pago:", this.metodo_pago);
	}
}


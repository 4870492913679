import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Images } from 'src/assets/data/images';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { LoginService } from 'src/app/services/util/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
 
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
    items: MenuItem[] | undefined;

    constructor(private router: Router,private loginService: LoginService) {}

    
    user : any =  localStorage.getItem('user');
    apellido_paterno : any =  localStorage.getItem('apellido_paterno');
    apellido_materno : any =  localStorage.getItem('apellido_materno');
    nombres : any =  localStorage.getItem('nombres');
    
    
    ngOnInit() {

        this.items = [
            // {
            //     label: 'Dashboard',
            //     icon: 'pi pi-fw pi-file',
            //     command: () => this.router.navigate(['/admin/dashboard'])
            // },
            {
                label: 'Productos',
                icon: 'pi pi-fw pi-align-right',
                command: () => this.router.navigate(['/admin/catalogo/productos']),
                styleClass: 'text-decoration-none'
            },
            // {
            //     label: 'Proveedor',
            //     icon: 'pi pi-fw pi-truck',
            //     command: () => this.router.navigate(['/admin/proveedor'])
                
            // },
            {
                label: 'Ventas',
                icon: 'pi pi-fw pi-shopping-cart',
                items: [
                    {
                        label: 'Gestión de Ventas',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => this.router.navigate(['/admin/proceso/proceso_venta'])
                     
                    }
                    // ,
                    // {
                    //     label: 'Registro Venta',
                    //     icon: 'pi pi-fw pi-calendar-times',
                    //     command: () => this.router.navigate(['/admin/proceso/Registro_Venta'])
                      
                    // }
                ]
            },
            {
                label: ' Almacén',
                icon: 'pi pi-fw pi-briefcase',
                items: [
                    {
                        label: 'Ingreso Almacén',
                        icon: 'pi pi-fw pi-briefcase',
                       
                        command: () => this.router.navigate(['/admin/almacen/reporte-almacen-ingreso']),
                        styleClass: 'text-decoration-none'
                    },
                    {
                        label: 'Gestión de Productos',
                        icon: 'pi pi-fw pi-inbox',
                        command: () => this.router.navigate(['/admin/almacen/reporte-almacen-stock']),
                        styleClass: 'text-decoration-none'
                    },
                    {
                        label: 'Movimiento Almacén',
                        icon: 'pi pi-fw pi-home',
                        command: () => this.router.navigate(['/admin/almacen/ingreso-almacen']),
                        styleClass: 'text-decoration-none'
                    }
                  
                ]
            },
            
            // {
            //     label: 'Quit',
            //     icon: 'pi pi-fw pi-power-off'
            // }
        ];
    }
    logout(): void {
        this.loginService.logout();
        window.location.href = '/login';
      }
}
